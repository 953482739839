/* eslint-disable camelcase, functional/no-class, functional/no-this-expression, functional/no-let */
import { User } from "./models";
import { RoleName } from "./models";

export class LoggedInUser {
  public user: User;
  private permissions: Set<RolePermissions>;
  public constructor(user: User) {
    this.user = user;
    this.permissions = new Set<RolePermissions>();

    user.actions?.forEach(action => {
      this.permissions.add(action);
    });
  }

  public isSystemAdmin(): boolean {
    return this.user.isSystemAdmin ? this.user.isSystemAdmin : false;
  }

  public isStudyAdmin(): boolean {
    return this.user.workAsRole?.name == RoleName.StudyAdmin;
  }

  public isCR(): boolean {
    return this.user.workAsRole?.name == RoleName.CR;
  }

  public isUploader(): boolean {
    return this.user.workAsRole?.name == RoleName.Uploader;
  }

  public can(requiredPermissions: ReadonlyArray<RolePermissions>): boolean {
    if (this.user.actions) {
      let result: boolean = false;

      requiredPermissions.forEach(requiredPermission => {
        if (result === null || !result) {
          result = this.permissions.has(requiredPermission);
        }
      });
      return result || false;
    } else {
      return false;
    }
  }
}

export function userIsAdmin(loggedinUser: LoggedInUser | null): boolean {
  return loggedinUser == null ? false : loggedinUser.isSystemAdmin();
}

export function userIsStudyAdmin(loggedinUser: LoggedInUser | null): boolean {
  return loggedinUser == null ? false : loggedinUser.user.workAsRole?.name == RoleName.StudyAdmin;
}

export function userIsIsc(loggedinUser: LoggedInUser | null): boolean {
  return loggedinUser == null ? false : loggedinUser.user.workAsRole?.name == RoleName.ISC;
}

export function userIsAdminish(loggedinUser: LoggedInUser | null): boolean {
  return loggedinUser == null
    ? false
    : userIsAdmin(loggedinUser) || userIsIsc(loggedinUser) || userIsStudyAdmin(loggedinUser);
}

export function userIsAdminOrReader(loggedinUser: LoggedInUser | null): boolean {
  return loggedinUser == null ? false : userIsAdmin(loggedinUser) || loggedinUser.isCR();
}

export function userIsLab(loggedinUser: LoggedInUser | null): boolean {
  return loggedinUser == null ? false : loggedinUser.user.workAsRole?.name == RoleName.LabTech;
}

export function userCanUpload(loggedinUser: LoggedInUser | null): boolean {
  return loggedinUser === null
    ? false
    : loggedinUser.can([RolePermissions.S_ImageListTab_UploadImage]);
}

export enum RolePermissions {
  IM_ImageListTab_ViewImageslistTab = "IM_ImageListTab_ViewImageslistTab",
  IM_ImagesListTab_ViewallImages_GlobalAccess = "IM_ImagesListTab_ViewallImages_GlobalAccess",
  IM_ImagesListTab_Viewassignedimagesonly_UnderAssignedStudies = "IM_ImagesListTab_Viewassignedimagesonly_UnderAssignedStudies",
  IM_ImagesListTab_AND_ViewImagesoriginatingfromLab = "IM_ImagesListTab_AND_ViewImagesoriginatingfromLab",
  IM_ImagesListTab_ExportImageManagementTable = "IM_ImagesListTab_ExportImageManagementTable",
  IM_ImagesListTab_DownloadImages = "IM_ImagesListTab_DownloadImages",
  IM_ImagesListTab_MoveImagsbetweenStudies = "IM_ImagesListTab_MoveImagsbetweenStudies",
  IM_ImagesListTab_MoveImagebetweenCases = "IM_ImagesListTab_MoveImagebetweenCases",
  IM_ImagesListTab_Search = "IM_ImagesListTab_Search",
  IM_ImagesListTab_Archive = "IM_ImagesListTab_Archive",
  IM_ImagesListTab_CopyImage = "IM_ImagesListTab_CopyImage",
  IM_ImagesListTab_UploadImagestoAssignedStudiesOnly = "IM_ImagesListTab_UploadImagestoAssignedStudiesOnly",
  IM_ImagesListTab_AssignImagetoCase = "IM_ImagesListTab_AssignImagetoCase",
  IM_CaseListTab_ViewPage = "IM_CaseListTab_ViewPage",
  IM_CaseListTab_ViewallCases = "IM_CaseListTab_ViewallCases",
  IM_CaseListTab_ViewAssignedCasesonly = "IM_CaseListTab_ViewAssignedCasesonly",
  IM_CaseListTab_Search = "IM_CaseListTab_Search",
  IM_CaseListTab_EditCase = "IM_CaseListTab_EditCase",
  IM_CaseListTab_ArchiveCase = "IM_CaseListTab_ArchiveCase",
  IM_CaseListTab_ChangeStatus = "IM_CaseListTab_ChangeStatus",
  IM_CaseListTab_PutoHold = "IM_CaseListTab_PutoHold",
  IM_CaseListTab_ExportCaseLevelReport = "IM_CaseListTab_ExportCaseLevelReport",
  IM_CaseListTab_DownloadImages = "IM_CaseListTab_DownloadImages",
  S_Main_ViewStudyPage = "S_Main_ViewStudyPage",
  S_Main_ViewAllStudies = "S_Main_ViewAllStudies",
  S_Main_ViewAssignedStudiesOnly = "S_Main_ViewAssignedStudiesOnly",
  S_Main_CreateStudy = "S_Main_CreateStudy",
  S_Main_EditStudy = "S_Main_EditStudy",
  S_CaseListTab_CreateCase = "S_CaseListTab_CreateCase",
  S_CaseListTab_EditCase = "S_CaseListTab_EditCase",
  S_CaseListTab_ViewallCasesUnderAssignedStudies = "S_CaseListTab_ViewallCasesUnderAssignedStudies",
  S_CaseListTab_ViewAssignedCasesonly = "S_CaseListTab_ViewAssignedCasesonly",
  S_ImageListTab_ViewImageTab = "S_ImageListTab_ViewImageTab",
  S_ImageListTab_UploadImage = "S_ImageListTab_UploadImage",
  S_ImageListTab_MoveImage = "S_ImageListTab_MoveImage",
  S_ImageListTab_CopyImage = "S_ImageListTab_CopyImage",
  AP_ImageViewer_ViewimageViewer = "AP_ImageViewer_ViewimageViewer",
  AP_ImageViewer_ViewLabel = "AP_ImageViewer_ViewLabel",
  AP_ImageViewer_ViewAnnotationsToolbar = "AP_ImageViewer_ViewAnnotationsToolbar",
  AP_ImageViewer_ViewAnnotations = "AP_ImageViewer_ViewAnnotations",
  AP_ImageViewer_EditCreateAnnotations = "AP_ImageViewer_EditCreateAnnotations",
  AP_ImageViewer_ViewButtonPassLabQC = "AP_ImageViewer_ViewButtonPassLabQC",
  AP_ImageViewer_Addcommenttoimage = "AP_ImageViewer_Addcommenttoimage",
  AP_CaseViewer_Viewcaseview = "AP_CaseViewer_Viewcaseview",
  AP_CaseViewer_ViewSidebar = "AP_CaseViewer_ViewSidebar",
  AP_CaseViewer_ViewQueries = "AP_CaseViewer_ViewQueries",
  AP_CaseViewer_ViewAuditTrail = "AP_CaseViewer_ViewAuditTrail",
  AP_CaseViewer_ViewButtonPassISCQC = "AP_CaseViewer_ViewButtonPassISCQC",
  AP_CaseViewer_MarkCaseasScored = "AP_CaseViewer_MarkCaseasScored",
  AP_CaseViewer_ManualCRassignment = "AP_CaseViewer_ManualCRassignment",
  AP_ImageData_ViewFilename = "AP_ImageData_ViewFilename",
  AP_ImageData_EditFileName = "AP_ImageData_EditFileName",
  AP_ImageData_ViewAnatomicalSegment = "AP_ImageData_ViewAnatomicalSegment",
  AP_ImageData_EditAnatomicalSegment = "AP_ImageData_EditAnatomicalSegment",
  AP_ImageData_ViewImageStatus = "AP_ImageData_ViewImageStatus",
  AP_ImageData_EditImageStatus = "AP_ImageData_EditImageStatus",
  AP_ImageData_ViewInQuery = "AP_ImageData_ViewInQuery",
  AP_ImageData_EditInQuery = "AP_ImageData_EditInQuery",
  AP_ImageData_ViewProtocolID = "AP_ImageData_ViewProtocolID",
  AP_ImageData_EditProtocolID = "AP_ImageData_EditProtocolID",
  AP_ImageData_ViewImageUploadDate = "AP_ImageData_ViewImageUploadDate",
  AP_ImageData_EditImageUploadDate = "AP_ImageData_EditImageUploadDate",
  AP_ImageData_ViewQAComments = "AP_ImageData_ViewQAComments",
  AP_ImageData_EditQAComments = "AP_ImageData_EditQAComments",
  AP_ImageData_ViewAccesionNumber = "AP_ImageData_ViewAccesionNumber",
  AP_ImageData_EditAccessionNumber = "AP_ImageData_EditAccessionNumber",
  AP_ImageData_ViewUploaderOrganization = "AP_ImageData_ViewUploaderOrganization",
  AP_ImageData_EditUploaderOrganization = "AP_ImageData_EditUploaderOrganization",
  AP_CaseData_ViewSiteID = "AP_CaseData_ViewSiteID",
  AP_CaseData_EditSiteID = "AP_CaseData_EditSiteID",
  AP_CaseData_ViewVisitID = "AP_CaseData_ViewVisitID",
  AP_CaseData_EditVisitID = "AP_CaseData_EditVisitID",
  AP_CaseData_ViewSubjectID = "AP_CaseData_ViewSubjectID",
  AP_CaseData_EditSubjectID = "AP_CaseData_EditSubjectID",
  AP_CaseData_ViewCollectionDate = "AP_CaseData_ViewCollectionDate",
  AP_CaseData_EditCollectionDate = "AP_CaseData_EditCollectionDate",
  AP_CaseData_ViewExpectedNoofimages = "AP_CaseData_ViewExpectedNoofimages",
  AP_CaseData_EditExpectedNoofImages = "AP_CaseData_EditExpectedNoofImages",
  AP_CaseData_ViewNoofimagesReceived = "AP_CaseData_ViewNoofimagesReceived",
  AP_CaseData_EditNoofImagesReceived = "AP_CaseData_EditNoofImagesReceived",
  AP_CaseData_ViewEndoProcedureID = "AP_CaseData_ViewEndoProcedureID",
  AP_CaseData_EditEndoProcedureID = "AP_CaseData_EditEndoProcedureID",
  AP_CaseData_ViewHistopathologyProcedureID = "AP_CaseData_ViewHistopathologyProcedureID",
  AP_CaseData_EditHistopathologyProcedureID = "AP_CaseData_EditHistopathologyProcedureID",
  AP_StudyData_ViewStudyName = "AP_StudyData_ViewStudyName",
  AP_StudyData_EditStudyName = "AP_StudyData_EditStudyName",
  AP_StudyData_EditStudyNamePopulated = "AP_StudyData_EditStudyNamePopulated",
  AP_StudyData_ViewIndication = "AP_StudyData_ViewIndication",
  AP_StudyData_EditIndication = "AP_StudyData_EditIndication",
  AP_StudyData_ViewIndicationPopulated = "AP_StudyData_ViewIndicationPopulated",
  AP_StudyData_ViewSponsor = "AP_StudyData_ViewSponsor",
  AP_StudyData_EditSponsor = "AP_StudyData_EditSponsor",
  AP_StudyData_EditSponsorPopulated = "AP_StudyData_EditSponsorPopulated",
  Q_ViewQueriesImages = "Q_ViewQueriesImages",
  Q_ViewQueriesCases = "Q_ViewQueriesCases",
  Q_ViewAllQueries = "Q_ViewAllQueries",
  Q_ViewQueriesAssignedtoUserRoleQueriesOnly = "Q_ViewQueriesAssignedtoUserRoleQueriesOnly",
  Q_ViewallQueriesunderAssignedStudies = "Q_ViewallQueriesunderAssignedStudies",
  Q_IssueCaseQuery = "Q_IssueCaseQuery",
  Q_IssueImageQueryStudy = "Q_IssueImageQueryStudy",
  Q_IssueImageQueryOrphanedImage = "Q_IssueImageQueryOrphanedImage",
  Q_Commentonassignedqueriesonly = "Q_Commentonassignedqueriesonly",
  Q_MarkQueryasResolved = "Q_MarkQueryasResolved",
  Q_Close_CancelQueryunderAssignedStudies = "Q_Close_CancelQueryunderAssignedStudies",
  Q_Close_CancelQueryunderUserRoleOnly = "Q_Close_CancelQueryunderUserRoleOnly",
  Q_Flagforfollowup = "Q_Flagforfollowup",
  Q_ReplaceImage = "Q_ReplaceImage",
  UM_ViewUsersPage = "UM_ViewUsersPage",
  UM_RegisterUser = "UM_RegisterUser",
  UM_GrantStudyAccess = "UM_GrantStudyAccess",
  UM_RevokeStudyAccess = "UM_RevokeStudyAccess",
  R_ViewReportsTab = "R_ViewReportsTab",
  R_GenerateAuditTrailPDFonly = "R_GenerateAuditTrailPDFonly",
  R_GenerateAuditTrailPDForCSV = "R_GenerateAuditTrailPDForCSV",
  R_GenerateIDR = "R_GenerateIDR",
  R_GenerateHPFAnnotationsReport = "R_GenerateHPFAnnotationsReport",
  R_GenerateQueryLevelAccessReport = "R_GenerateQueryLevelAccessReport",
  R_GenerateStudyLevelAccessReport = "R_GenerateStudyLevelAccessReport",
  QP_SystemAdminAssignedtostudytheImageCaseQuery = "QP_SystemAdminAssignedtostudytheImageCaseQuery",
  QP_StudyAdminAssignedtostudytheImageCaseQuery = "QP_StudyAdminAssignedtostudytheImageCaseQuery",
  QP_ISCAssignedtostudytheImageCaseQuery = "QP_ISCAssignedtostudytheImageCaseQuery",
  QP_DMAssignedtostudytheImageCaseQuery = "QP_DMAssignedtostudytheImageCaseQuery",
  QP_PMAssignedtostudytheImageCaseQuery = "QP_PMAssignedtostudytheImageCaseQuery",
  QP_SponsorAssignedtostudytheImageCaseQuery = "QP_SponsorAssignedtostudytheImageCaseQuery",
  QP_SiteAssignedtostudytheImageCaseQuery = "QP_SiteAssignedtostudytheImageCaseQuery",
  QP_UploaderAssignedtostudytheImageCaseQuery = "QP_UploaderAssignedtostudytheImageCaseQuery",
  QP_CentralReaderAssignedtostudytheImageCaseQuery = "QP_CentralReaderAssignedtostudytheImageCaseQuery",
  QP_LabTechAssignedtostudytheImageCaseQuery = "QP_LabTechAssignedtostudytheImageCaseQuery"
}
