import qs from "qs";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Appearance, Button, Icon, Text } from "@blasterjs/core";
import { Case } from "../models";
import TopBar from "./TopBar";
import { useAppSelector } from "../hooks";

interface Props {
  readonly histoCase: Case;
}

const TopBarCase = ({ histoCase }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const returnTab = () => {
    return queryParams.tab || "";
  };
  const embedded = useAppSelector(state => state.config.embedded);
  const close = (histoCase: Case) => () => navigate(`/studies/${histoCase.studyId}/${returnTab()}`);
  const closeButton = embedded ? (
    <Text>&nbsp; </Text>
  ) : (
    <Button appearance={Appearance.MINIMAL} color="#fff" onClick={close(histoCase)}>
      <Icon name="close" />
    </Button>
  );
  return (
    <TopBar>
      {closeButton}
      {histoCase.procId}
    </TopBar>
  );
};

export default TopBarCase;
