import L, { LatLngLiteral } from "leaflet";
import React from "react";
import { Marker } from "react-leaflet";

import { UUID } from "../models";
import AnnotationPopup from "./AnnotationPopup";
import { AppDispatch } from "../store";

interface Props {
  readonly text: string;
  readonly date: string;
  readonly position: LatLngLiteral;
  readonly annotationId: UUID;
  readonly canDelete: boolean;
  dispatch: AppDispatch;
}

const TextMarker = ({ text, date, position, annotationId, canDelete, dispatch }: Props) => {
  const myIcon = L.icon({
    iconAnchor: [12, 12],
    iconSize: [25, 25],
    iconUrl: "/images/icon-text.svg",
    popupAnchor: [0, -4]
  });

  return (
    <Marker icon={myIcon} position={position}>
      <AnnotationPopup
        text={text}
        date={date}
        annotationId={annotationId}
        canDelete={canDelete}
        dispatch={dispatch}
      />
    </Marker>
  );
};

export default TextMarker;
