import React, { useState, useEffect } from "react";
import { Button, Text, Box, Heading } from "@blasterjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import {
  QueryObjectType,
  QueryStatus,
  CaseWithImages,
  ImageAndQuery,
  QueryDetailRecord,
  QueryDetailComment,
  QueryDetail,
  QueryDetails,
  RoleName,
  QueryResolutionType
} from "../models";
import { useAppSelector, useAppDispatch } from "../hooks";
import {
  queryDetailsMetadataFetch,
  queryDetailsFetchByCase,
  queryDetailsFetchByImage,
  setCurrentViewQueryObjectType
} from "../slices/queryDetails";
import RespondQueryDialog from "./RespondQueryDialog";
import CloseQueryDialog from "./CloseQueryDialog";
import CancelQueryDialog from "./CancelQueryDialog";
import CommentQueryDialog from "./CommentQueryDialog";
import FollowUpQueryDialog from "./FollowUpQueryDialog";
import ReplaceImage from "./ReplaceImage";
import CreateQueryFlow from "./CreateQueryFlow";
import { toggleSidebarExpanded } from "../slices/caseImageViewer";
import { CollapseIcon } from "./Expandable";
import { userSummariesFetch } from "../slices/users";
import DateYearMonthDay from "./DateYearMonthDay";
import DateYearMonthDayTime from "./DateYearMonthDayTime";
import { LoggedInUser, RolePermissions } from "../permissions";

interface QueryListProps {
  readonly histoCase: CaseWithImages | null;
  readonly selectedImage: ImageAndQuery | null;
  readonly queryObjectType: QueryObjectType;
  readonly loggedInUser: LoggedInUser;
}

const QueryList = ({ histoCase, selectedImage, queryObjectType, loggedInUser }: QueryListProps) => {
  const dispatch = useAppDispatch();

  const studyId =
    queryObjectType === QueryObjectType.Case && histoCase
      ? histoCase.caseWithStatus.studyId
      : selectedImage && selectedImage.image.studyId
      ? selectedImage.image.studyId
      : null;
  const caseId =
    queryObjectType === QueryObjectType.Case && histoCase
      ? histoCase.caseWithStatus.id
      : selectedImage && selectedImage.image.caseId
      ? selectedImage.image.caseId
      : "";
  const imageId =
    queryObjectType === QueryObjectType.Image && selectedImage ? selectedImage.image.id : "";

  // Store state variables
  const userSummariesResource = useAppSelector(state => state.users.userSummaries);

  const queryDetailsMetadataResource = useAppSelector(
    state => state.queryDetails.queryDetailsMetadata
  );
  const queryDetailsMetadata =
    "resource" in queryDetailsMetadataResource ? queryDetailsMetadataResource.resource : null;

  const queryDetailsResource = useAppSelector(state => state.queryDetails.queryDetails);
  const queryDetails = "resource" in queryDetailsResource ? queryDetailsResource.resource : [];

  const userRoleName = loggedInUser.user.workAsRole?.name || null;
  const userRoleId = loggedInUser.user.workAsRole?.id || null;
  const userId = loggedInUser.user.id;

  const userSummaries =
    "resource" in userSummariesResource ? userSummariesResource.resource.slice() : [];

  const queryCaseReasons =
    "resource" in queryDetailsMetadataResource
      ? queryDetailsMetadataResource.resource.caseReasons.slice()
      : [];
  const queryImageReasons =
    "resource" in queryDetailsMetadataResource
      ? queryDetailsMetadataResource.resource.imageReasons.slice()
      : [];
  const queryRoles =
    "resource" in queryDetailsMetadataResource
      ? queryDetailsMetadataResource.resource.roles.slice()
      : [];

  // Local state variables
  const [selectedQueryTab, setSelectedQueryTab] = useState(queryObjectType);
  const [selectedQueryTypeId, setSelectedQueryTypeId] = useState(
    queryObjectType === QueryObjectType.Case && histoCase && histoCase.caseWithStatus.id
      ? histoCase.caseWithStatus.id
      : selectedImage && selectedImage.image.id
      ? selectedImage.image.id
      : ""
  );
  const [createQuery, setCreateQuery] = useState(false);

  const [closedQueryTabOpen, setClosedQueryTabOpen] = useState(false);
  const [openQueryTabOpen, setOpenQueryTabOpen] = useState(false);
  const [answeredQueryTabOpen, setAnsweredQueryTabOpen] = useState(false);

  const [openResponseDialog, setOpenResponseDialog] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [openFollowUpDialog, setOpenFollowUpDialog] = useState(false);

  const [updateQuery, setUpdateQuery] = useState<QueryDetailRecord>({} as QueryDetailRecord);
  const [updateQueryComments, setUpdateQueryComments] = useState<Array<QueryDetailComment>>([]);

  const onClosedQueryTabOpenClick = () => {
    setClosedQueryTabOpen(!closedQueryTabOpen);
  };
  const onOpenQueryTabOpenClick = () => {
    setOpenQueryTabOpen(!openQueryTabOpen);
  };
  const onAnsweredQueryTabOpenClick = () => {
    setAnsweredQueryTabOpen(!answeredQueryTabOpen);
  };

  useEffect(() => {
    if (queryObjectType === QueryObjectType.Case) {
      dispatch(queryDetailsFetchByCase(caseId));
      dispatch(
        setCurrentViewQueryObjectType({
          queryViewObjectType: queryObjectType,
          viewObjectId: caseId
        })
      );
    } else if (queryObjectType === QueryObjectType.Image) {
      dispatch(queryDetailsFetchByImage(imageId));
      dispatch(
        setCurrentViewQueryObjectType({
          queryViewObjectType: queryObjectType,
          viewObjectId: imageId
        })
      );
    }

    dispatch(userSummariesFetch());
    dispatch(queryDetailsMetadataFetch(studyId));
  }, [setSelectedQueryTab]);

  const openCaseQueries = queryDetails.filter(queryDetail => {
    const query = queryDetail.query;
    return (
      queryObjectType === QueryObjectType.Case &&
      histoCase &&
      query.caseId === histoCase.caseWithStatus.id &&
      query.objectType === QueryObjectType.Case &&
      query.queryStatus === QueryStatus.Open &&
      query.resolution !== QueryResolutionType.Resolved
    );
  });

  const answeredCaseQueries = queryDetails.filter(queryDetail => {
    const query = queryDetail.query;
    return (
      queryObjectType === QueryObjectType.Case &&
      histoCase &&
      query.caseId === histoCase.caseWithStatus.id &&
      query.objectType === QueryObjectType.Case &&
      query.queryStatus === QueryStatus.Open &&
      query.resolution === QueryResolutionType.Resolved
    );
  });

  const closedCaseQueries = queryDetails.filter(queryDetail => {
    const query = queryDetail.query;
    return (
      queryObjectType === QueryObjectType.Case &&
      histoCase &&
      query.caseId === histoCase.caseWithStatus.id &&
      query.objectType === QueryObjectType.Case &&
      query.queryStatus === QueryStatus.Closed
    );
  });

  const openImageQueries = queryDetails.filter(queryDetail => {
    const query = queryDetail.query;
    return (
      selectedImage &&
      query.objectId === selectedImage.image.id &&
      query.queryStatus === QueryStatus.Open &&
      query.resolution !== QueryResolutionType.Resolved
    );
  });

  const answeredImageQueries = queryDetails.filter(queryDetail => {
    const query = queryDetail.query;
    return (
      selectedImage &&
      query.objectId === selectedImage.image.id &&
      query.queryStatus === QueryStatus.Open &&
      query.resolution === QueryResolutionType.Resolved
    );
  });

  const closedImageQueries = queryDetails.filter(queryDetail => {
    const query = queryDetail.query;
    return (
      selectedImage &&
      query.objectId === selectedImage.image.id &&
      query.queryStatus === QueryStatus.Closed
    );
  });

  // Helper functions
  const getSelectableRoles = (queryType: QueryObjectType) => {
    if (queryType === QueryObjectType.Case) {
      return queryRoles.filter(
        role => queryCaseReasons.find(reason => role.id === reason.roleId) !== undefined
      );
    } else if (queryType === QueryObjectType.Image) {
      return queryRoles.filter(
        role => queryImageReasons.find(reason => role.id === reason.roleId) !== undefined
      );
    } else {
      return [];
    }
  };

  const getSelectableReasons = (queryType: QueryObjectType) => {
    if (queryType === QueryObjectType.Case) {
      return queryCaseReasons.slice();
    } else if (queryType === QueryObjectType.Image) {
      return queryImageReasons.slice();
    } else {
      return [];
    }
  };

  const canUserRoleReplaceImage = (userRoleName: RoleName) => {
    return (
      userRoleName === RoleName.StudyAdmin ||
      userRoleName === RoleName.ISC ||
      userRoleName === RoleName.LabTech ||
      userRoleName === RoleName.Uploader
    );
  };

  // All buttons
  const collapseIcon = () => <CollapseIcon onToggle={() => dispatch(toggleSidebarExpanded())} />;

  const openCreateQueryButton = () => {
    const onOpenCreateQueryButtonClick = () => {
      setCreateQuery(true);
    };

    return (
      <>
        {((loggedInUser.can([RolePermissions.Q_IssueCaseQuery]) &&
          selectedQueryTab == QueryObjectType.Case) ||
          (loggedInUser.can([RolePermissions.Q_IssueImageQueryStudy]) &&
            selectedQueryTab == QueryObjectType.Image)) && (
          <Button
            width="100%"
            style={{ color: "#007EB5", border: "1px solid #007EB5" }}
            onClick={onOpenCreateQueryButtonClick}
          >
            Open{" "}
            {selectedQueryTab === QueryObjectType.Image
              ? "Image"
              : selectedQueryTab === QueryObjectType.Case
              ? "Case"
              : null}{" "}
            Query
          </Button>
        )}
      </>
    );
  };

  const respondButton = (queryDetail: QueryDetail) => {
    const onRespondButtonClick = () => {
      setUpdateQuery(queryDetail.query);
      setUpdateQueryComments(queryDetail.comments);
      setOpenResponseDialog(!openResponseDialog);
    };

    return (
      <>
        {loggedInUser.can([RolePermissions.Q_MarkQueryasResolved]) && (
          <Button
            key={queryDetail.query.id + "respond-button"}
            onClick={onRespondButtonClick}
            className="query-blue-button"
            width="100%"
          >
            Mark as Resolved
          </Button>
        )}
      </>
    );
  };

  const commentButton = (queryDetail: QueryDetail) => {
    const onCommentButtonClick = () => {
      setUpdateQuery(queryDetail.query);
      setUpdateQueryComments(queryDetail.comments);
      setOpenCommentDialog(!openCommentDialog);
    };

    return (
      <>
        {loggedInUser.can([RolePermissions.Q_Commentonassignedqueriesonly]) && (
          <Button
            key={queryDetail.query.id + "comment-button"}
            onClick={onCommentButtonClick}
            className="query-white-button"
            width="100%"
          >
            Comment
          </Button>
        )}
      </>
    );
  };

  const followUpButton = (queryDetail: QueryDetail) => {
    const followUpButtonClick = () => {
      setUpdateQuery(queryDetail.query);
      setUpdateQueryComments(queryDetail.comments);
      setOpenFollowUpDialog(!openFollowUpDialog);
    };

    return (
      <>
        {loggedInUser.can([RolePermissions.Q_Flagforfollowup]) && (
          <Button
            key={queryDetail.query.id + "follow-up-button"}
            onClick={followUpButtonClick}
            className="query-white-button"
            width="100%"
          >
            Follow Up
          </Button>
        )}
      </>
    );
  };

  const replaceImageButton = (query: QueryDetailRecord) => {
    if (selectedImage !== null) {
      return <ReplaceImage key={query.id + "replace-image-button"} imageAndQuery={selectedImage} />;
    }
  };

  const cancelButton = (queryDetail: QueryDetail) => {
    const onCancelButtonClick = () => {
      setUpdateQuery(queryDetail.query);
      setUpdateQueryComments(queryDetail.comments);
      setOpenCancelDialog(!openCancelDialog);
    };

    return (
      <>
        <Button
          key={queryDetail.query.id + "cancel-button"}
          onClick={onCancelButtonClick}
          className="query-white-button"
          width="100%"
        >
          Cancel
        </Button>
      </>
    );
  };

  const closeQueryButton = (queryDetail: QueryDetail) => {
    const onCloseQueryButtonClick = () => {
      setUpdateQuery(queryDetail.query);
      setUpdateQueryComments(queryDetail.comments);
      setOpenCloseDialog(!openCloseDialog);
    };

    return (
      <>
        {loggedInUser.can([
          RolePermissions.Q_Close_CancelQueryunderUserRoleOnly,
          RolePermissions.Q_Close_CancelQueryunderAssignedStudies
        ]) && (
          <Button
            key={queryDetail.query.id + "close-button"}
            onClick={onCloseQueryButtonClick}
            className="query-blue-button"
            width="100%"
          >
            Close Query
          </Button>
        )}
      </>
    );
  };

  // Button groupings
  const issuerAndRecipientOpenQueryButtons = (queryDetail: QueryDetail) => {
    const canReplaceImage = userRoleName ? canUserRoleReplaceImage(userRoleName) : false;

    const buttons = (
      <Box display="flex" flexDirection="column" alignItems="center" mt={1} mb={1} width="100%">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box width="49%">{respondButton(queryDetail)}</Box>
          <Box width="49%">{cancelButton(queryDetail)}</Box>
        </Box>
        {canReplaceImage ? (
          <Box mt={1} width="100%">
            {replaceImageButton(queryDetail.query)}
          </Box>
        ) : null}
        <Box display="flex" justifyContent="space-between" mt={1} width="100%">
          <Box width="49%">{followUpButton(queryDetail)}</Box>
          <Box width="49%">{commentButton(queryDetail)}</Box>
        </Box>
      </Box>
    );

    return buttons;
  };

  const issuerOpenQueryButtons = (queryDetail: QueryDetail) => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={1} mb={1} width="100%">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box width="49%">{followUpButton(queryDetail)}</Box>
          <Box width="49%">{cancelButton(queryDetail)}</Box>
        </Box>
        <Box mt={1} width="100%">
          {commentButton(queryDetail)}
        </Box>
      </Box>
    );
  };

  const recipientOpenQueryButtons = (queryDetail: QueryDetail) => {
    const canReplaceImage = userRoleName ? canUserRoleReplaceImage(userRoleName) : false;

    const buttons = (
      <Box display="flex" flexDirection="column" alignItems="center" mt={1} mb={1} width="100%">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box width="49%">{respondButton(queryDetail)}</Box>
          <Box width="49%">{commentButton(queryDetail)}</Box>
        </Box>
        {canReplaceImage ? (
          <Box mt={1} width="100%">
            {replaceImageButton(queryDetail.query)}
          </Box>
        ) : null}
      </Box>
    );

    return buttons;
  };

  const notIssuerOrRecepientOpenQueryButtons = (queryDetail: QueryDetail) => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={1} mb={1} width="100%">
        <Box mt={1} width="100%">
          {commentButton(queryDetail)}
        </Box>
      </Box>
    );
  };

  const issuerAnsweredQueriesButtons = (queryDetail: QueryDetail) => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={1} mb={1} width="100%">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box width="49%">{closeQueryButton(queryDetail)}</Box>
          <Box width="49%">{commentButton(queryDetail)}</Box>
        </Box>
      </Box>
    );
  };

  const imageCaseSelectionTab = () => {
    const onImageCaseSelectionTabClick = (type: QueryObjectType) => {
      setOpenQueryTabOpen(false);
      setClosedQueryTabOpen(false);
      setAnsweredQueryTabOpen(false);

      setSelectedQueryTab(type);
      setSelectedQueryTypeId(
        type === QueryObjectType.Case && histoCase && histoCase.caseWithStatus.id
          ? histoCase.caseWithStatus.id
          : selectedImage && selectedImage.image.id
          ? selectedImage.image.id
          : ""
      );
    };

    return (
      <>
        <Box>
          <Button
            className={
              selectedQueryTab === QueryObjectType.Image
                ? "image-case-button-clicked"
                : "image-case-button-unclicked"
            }
            onClick={() => onImageCaseSelectionTabClick(QueryObjectType.Image)}
          >
            Image
          </Button>
          <Button
            className={
              selectedQueryTab === QueryObjectType.Case
                ? "image-case-button-clicked"
                : "image-case-button-unclicked"
            }
            onClick={() => onImageCaseSelectionTabClick(QueryObjectType.Case)}
          >
            Case
          </Button>
        </Box>
        <br />
      </>
    );
  };

  const createCommentList = (comments: Array<QueryDetailComment>) => {
    const commentsList = comments.map(comment => {
      return (
        <>
          <Text style={{ fontWeight: 400, fontSize: 12 }}>
            {comment.username} - <DateYearMonthDay date={comment.createdAt} />
          </Text>
          <br />
          <Text className={"query-list-result"}>{comment.commentText}</Text>
          <br />
          <br />
        </>
      );
    });

    return commentsList;
  };

  const createQueryList = (queryDetails: QueryDetails) => {
    const queryDetailsList = queryDetails.map(queryDetail => {
      const query = queryDetail.query;
      const comments = queryDetail.comments;

      const queryWith = queryDetailsMetadata
        ? queryDetailsMetadata.roles.find(role => role.id === query.withRoleId)
        : null;
      const queryWithText = queryWith ? queryWith.name : "";

      const isRecipient = query.withRoleId === userRoleId;
      const isIssuer = query.openedByRoleId === userRoleId;
      const isOpenQuery =
        query.queryStatus === QueryStatus.Open && query.resolution !== QueryResolutionType.Resolved;
      const isAnsweredQuery =
        query.queryStatus === QueryStatus.Open && query.resolution === QueryResolutionType.Resolved;
      const isClosedQuery = query.queryStatus === QueryStatus.Closed;

      const getButtonGrouping = () => {
        if (isOpenQuery) {
          if (isIssuer && isRecipient) {
            return issuerAndRecipientOpenQueryButtons(queryDetail);
          } else if (isIssuer) {
            return issuerOpenQueryButtons(queryDetail);
          } else if (isRecipient) {
            return recipientOpenQueryButtons(queryDetail);
          } else {
            return notIssuerOrRecepientOpenQueryButtons(queryDetail);
          }
        } else if (isAnsweredQuery) {
          if (isIssuer) {
            return issuerAnsweredQueriesButtons(queryDetail);
          }
        }

        return null;
      };

      return (
        <Box key={query.id} style={{ marginBottom: "20px", backgroundColor: "#F9F9FA" }}>
          {(query.queryStatus === QueryStatus.Open &&
            query.resolution === QueryResolutionType.Resolved) ||
          query.queryStatus === QueryStatus.Closed ? (
            <>
              <Box display="flex" width="100%">
                <Text textAlign="left" className={"query-list-header"}>
                  Response
                </Text>
                <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
                  {query.resolutionOption}
                </Text>
              </Box>
              <hr />
              <Box display="flex" width="100%">
                <Text textAlign="left" className={"query-list-header"}>
                  Response Comments
                </Text>
                <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
                  {query.resolutionText}
                </Text>
              </Box>
              <hr />
              <Box display="flex" width="100%">
                <Text textAlign="left" className={"query-list-header"}>
                  Response by
                </Text>
                <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
                  {userSummaries.find(user => user.id === query.resolvedBy)?.username}
                </Text>
              </Box>
              <hr />
            </>
          ) : null}
          {query.objectType === QueryObjectType.Image ? (
            <>
              <Box display="flex" width="100%">
                <Text textAlign="left" className={"query-list-header"}>
                  File Name
                </Text>
                <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
                  {selectedImage ? selectedImage.image.name : ""}
                </Text>
              </Box>
              <hr />
            </>
          ) : null}
          <Box display="flex" width="100%">
            <Text textAlign="left" className={"query-list-header"}>
              Query ID
            </Text>
            <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
              {query.id}
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className={"query-list-header"}>
              Query With
            </Text>
            <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
              {queryWithText}
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className={"query-list-header"}>
              Reason
            </Text>
            <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
              {query.reasonText} {query.childReasonText ? " - " + query.childReasonText : ""}
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className={"query-list-header"}>
              Follow Up
            </Text>
            <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
              <DateYearMonthDayTime date={query.latestFollowUpOnDt} />
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className={"query-list-header"}>
              Comments
            </Text>
            <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
              {createCommentList(comments)}
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className={"query-list-header"}>
              Query Opened
            </Text>
            <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
              <DateYearMonthDay date={query.openedAt} />
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className={"query-list-header"}>
              Query Opened By
            </Text>
            <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
              {query.createdBy}
            </Text>
          </Box>
          {isClosedQuery ? (
            <>
              <hr />
              <Box display="flex" width="100%">
                <Text textAlign="left" className={"query-list-header"}>
                  Closed Date
                </Text>
                <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
                  <DateYearMonthDay date={query.closedAt} />
                </Text>
              </Box>
              <hr />
              <Box display="flex" width="100%">
                <Text textAlign="left" className={"query-list-header"}>
                  Closed By
                </Text>
                <Text ml={"auto"} textAlign="right" className={"query-list-result"}>
                  {userSummaries.find(user => user.id === query.closedBy)?.username}
                </Text>
              </Box>
            </>
          ) : null}
          <Box></Box>
          {getButtonGrouping()}
        </Box>
      );
    });

    return (
      <Box style={{ width: "100%", borderRadius: "8px", marginTop: "5px" }}>{queryDetailsList}</Box>
    );
  };

  const createQueriesContainer = (
    openQueries: QueryDetails,
    answeredQueries: QueryDetails,
    closedQueries: QueryDetails
  ) => {
    return (
      <>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Text
            style={{
              backgroundColor: "#5FAED2",
              width: "30px",
              height: "24px",
              display: "flex",
              justifyContent: "center",
              borderRadius: "8px",
              color: "#F9F9FA"
            }}
          >
            {openQueries.length}
          </Text>
          <Heading as="h6" ml="1">
            Open Queries
          </Heading>
          <Box ml={"auto"}>
            <button
              style={{ border: "none", backgroundColor: "inherit", cursor: "pointer" }}
              onClick={onOpenQueryTabOpenClick}
            >
              {openQueryTabOpen ? (
                <FontAwesomeIcon icon={faAngleDown} size="1x" style={{ color: "#007EB5" }} />
              ) : (
                <FontAwesomeIcon icon={faAngleUp} size="1x" style={{ color: "#007EB5" }} />
              )}
            </button>
          </Box>
        </Box>

        {openQueries.length > 0 && openQueryTabOpen ? createQueryList(openQueries) : null}

        <Box style={{ display: "flex", alignItems: "center" }}>
          <Text
            style={{
              backgroundColor: "#5FAED2",
              width: "30px",
              height: "24px",
              display: "flex",
              justifyContent: "center",
              borderRadius: "8px",
              color: "#F9F9FA"
            }}
          >
            {answeredQueries.length}
          </Text>
          <Heading as="h6" ml="1">
            Answered Queries
          </Heading>
          <Box ml={"auto"}>
            <button
              style={{ border: "none", backgroundColor: "inherit", cursor: "pointer" }}
              onClick={onAnsweredQueryTabOpenClick}
            >
              {answeredQueryTabOpen ? (
                <FontAwesomeIcon icon={faAngleDown} size="1x" style={{ color: "#007EB5" }} />
              ) : (
                <FontAwesomeIcon icon={faAngleUp} size="1x" style={{ color: "#007EB5" }} />
              )}
            </button>
          </Box>
        </Box>

        {answeredQueries.length > 0 && answeredQueryTabOpen
          ? createQueryList(answeredQueries)
          : null}

        <Box style={{ display: "flex", alignItems: "center" }}>
          <Text
            style={{
              backgroundColor: "#5FAED2",
              width: "30px",
              height: "24px",
              display: "flex",
              justifyContent: "center",
              borderRadius: "8px",
              color: "#F9F9FA"
            }}
          >
            {closedQueries.length}
          </Text>
          <Heading as="h6" ml="1">
            Closed Queries
          </Heading>
          <Box ml={"auto"}>
            <button
              style={{ border: "none", backgroundColor: "inherit", cursor: "pointer" }}
              onClick={onClosedQueryTabOpenClick}
            >
              {closedQueryTabOpen ? (
                <FontAwesomeIcon icon={faAngleDown} size="1x" style={{ color: "#007EB5" }} />
              ) : (
                <FontAwesomeIcon icon={faAngleUp} size="1x" style={{ color: "#007EB5" }} />
              )}
            </button>
          </Box>
        </Box>

        {closedQueries.length > 0 && closedQueryTabOpen ? createQueryList(closedQueries) : null}
      </>
    );
  };

  if (!createQuery) {
    return (
      <>
        <Box mt={10} style={{ backgroundColor: "#FFFFFF" }}>
          <Box display="flex" width="100%" mb={2}>
            <Box textAlign="left">
              <Heading as="h5">Queries</Heading>
            </Box>
            <Box ml={"auto"}>{collapseIcon()}</Box>
          </Box>
          <hr />

          {openCreateQueryButton()}

          <br />
          <br />

          {queryObjectType === QueryObjectType.Case ? imageCaseSelectionTab() : null}

          {queryObjectType === QueryObjectType.Case
            ? selectedQueryTab === QueryObjectType.Case
              ? createQueriesContainer(openCaseQueries, answeredCaseQueries, closedCaseQueries)
              : createQueriesContainer(openImageQueries, answeredImageQueries, closedImageQueries)
            : queryObjectType === QueryObjectType.Image
            ? createQueriesContainer(openImageQueries, answeredImageQueries, closedImageQueries)
            : null}
        </Box>

        <RespondQueryDialog
          key={"respond-dialog"}
          openDialog={openResponseDialog}
          setOpenDialog={setOpenResponseDialog}
          queryId={updateQuery.id}
        />
        <CommentQueryDialog
          key={"comment-dialog"}
          openDialog={openCommentDialog}
          setOpenDialog={setOpenCommentDialog}
          query={updateQuery}
          issuer={updateQuery.openedBy === userId}
        />
        <FollowUpQueryDialog
          key={"follow-up-dialog"}
          openDialog={openFollowUpDialog}
          setOpenDialog={setOpenFollowUpDialog}
          query={updateQuery}
        />
        <CancelQueryDialog
          key={"cancel-dialog"}
          openDialog={openCancelDialog}
          setOpenDialog={setOpenCancelDialog}
          query={updateQuery}
          comments={updateQueryComments}
          imageName={
            selectedQueryTab === QueryObjectType.Case ? undefined : selectedImage?.image.name
          }
        />
        <CloseQueryDialog
          key={"close-dialog"}
          openDialog={openCloseDialog}
          setOpenDialog={setOpenCloseDialog}
          query={updateQuery}
          comments={updateQueryComments}
          imageName={
            selectedQueryTab === QueryObjectType.Case ? undefined : selectedImage?.image.name
          }
        />
      </>
    );
  } else {
    return (
      <Box>
        <CreateQueryFlow
          createQuery={createQuery}
          setCreateQuery={setCreateQuery}
          reasons={getSelectableReasons(selectedQueryTab)}
          roles={getSelectableRoles(selectedQueryTab)}
          queryType={selectedQueryTab}
          queryTypeId={selectedQueryTypeId}
          studyId={studyId}
          caseId={caseId}
        />
      </Box>
    );
  }
};

export default QueryList;
