import { Icon } from "@blasterjs/core";
import { Popup } from "react-leaflet";
import React from "react";
import { UUID } from "../models";
import { deleteAnnotation } from "../slices/caseImageViewer";
import { AppDispatch } from "../store";

interface Props {
  readonly text?: string | null;
  readonly date: string;
  readonly annotationId: UUID;
  readonly canDelete: boolean;
  dispatch: AppDispatch;
}

export const deleteImageAnnotation = (annotationId: UUID, dispatch: AppDispatch) => () => {
  dispatch(deleteAnnotation({ annotationId: annotationId, deleteNested: false }));
};
/*
 * Popup shown when annotations are clicked.
 */
const AnnotationPopup = ({ canDelete, text, date, annotationId, dispatch }: Props) => (
  <Popup>
    <div style={{ fontSize: "16px" }}>{text}</div>
    <div>{date}</div>
    {canDelete ? (
      <Icon
        name="trash"
        onClick={deleteImageAnnotation(annotationId, dispatch)}
        style={{ cursor: "pointer" }}
      />
    ) : null}
  </Popup>
);

export default AnnotationPopup;
