import { Box, Button as BlasterButton, Icon } from "@blasterjs/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { AnnotationClass, AnnotationClassType, AnnotationClassWithCount, UUID } from "../models";
import TooltipFlyout from "./Tooltip";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  AnnotationType,
  beginAnnotation,
  beginDroppingAnnotation,
  cancelImageAnnotation,
  SelectedAnnotationType
} from "../slices/caseImageViewer";
import { LoggedInUser, RolePermissions } from "../permissions";

const Dot = styled.span`
  height: 12px;
  width: 12px;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: inline-block;
`;

const AnnotationClassesStyled = styled.div`
  display: flex;
  padding: 4px;
  flex-direction: column;
  width: 300px;
`;

const AnnotationClassesWrapperStyled = styled.div`
  max-height: 190px;
  overflow: auto;
  overflow-x: none;
  margin-bottom: -8px;
`;

const AnnotationClassStyled = styled.div`
  display: flex;
  background-color: ${props => props.color};
  justify-content: space-between;
  padding: 2px 4px;
`;

const AnnotationClassesHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin: -4px 0;
`;

const AnnotationClassesButton = <T extends AnnotationClassType>({
  annotationType,
  isShown,
  annotationClassesWithCount,
  selectedAnnotationClass,
  onClose,
  onSelectAnnotationClass,
  userCanCreateAnnotations
}: {
  //readonly annotationType: AnnotationType.Point | AnnotationType.Freehand;
  readonly annotationType: AnnotationClassType;
  readonly isShown: boolean;
  readonly annotationClassesWithCount: ReadonlyArray<AnnotationClassWithCount<T>>;
  readonly selectedAnnotationClass: AnnotationClass<T> | null;
  readonly onClose: () => void;
  readonly onSelectAnnotationClass: (annotationClass: AnnotationClass<T>) => void;
  readonly userCanCreateAnnotations: boolean;
}) => {
  return (
    <>
      {annotationType === AnnotationType.Point
        ? "Point"
        : annotationType === AnnotationType.HPF
        ? "HPF"
        : "Freehand"}
      <TooltipFlyout
        placement={"top-end"}
        show={isShown}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <AnnotationClassesStyled>
          <AnnotationClassesHeaderStyled>
            <Box style={{ fontWeight: "600" }}>
              {annotationType === AnnotationType.Point
                ? "Point annotations"
                : annotationType === AnnotationType.HPF
                ? "HPF annotations"
                : "Freehand annotations"}
            </Box>
            <Icon name="cross" onClick={onClose} style={{ cursor: "pointer", marginTop: "4px" }} />
          </AnnotationClassesHeaderStyled>
          <Box style={{ margin: "0 -4px" }}>
            <hr />
          </Box>
          <AnnotationClassesWrapperStyled>
            {annotationClassesWithCount.map(
              ({ annotationClass, count }: AnnotationClassWithCount<T>) => (
                <AnnotationClassStyled
                  key={annotationClass.name}
                  color={
                    selectedAnnotationClass && selectedAnnotationClass.id === annotationClass.id
                      ? "#D0D3D9"
                      : "#fff"
                  }
                  onClick={() => {
                    onSelectAnnotationClass(annotationClass);
                  }}
                  {...(userCanCreateAnnotations ? { style: { cursor: "pointer" } } : {})}
                >
                  <Box>
                    <Dot color={annotationClass.color} style={{ marginRight: "4px" }} />
                    {annotationClass.name}
                  </Box>
                  <Box>{count}</Box>
                </AnnotationClassStyled>
              )
            )}
          </AnnotationClassesWrapperStyled>
        </AnnotationClassesStyled>
      </TooltipFlyout>
    </>
  );
};

const AnnotationButtonsStyled = styled.div`
  margin-top: auto;
`;

interface Props {
  readonly loggedInUser: LoggedInUser;
  readonly imageId: UUID;
  readonly isAnnotationInProgress: boolean;
  readonly hpfAnnotationCount: number;
  readonly pointAnnotationCount: number;
  readonly freehandAnnotationCount: number;
  readonly hpfCursorVisible: boolean;
  readonly setHpfCursorVisible: (flag: boolean) => void;
  readonly setShowLineLabels: (flag: boolean) => void;
}

const AnnotationButton = styled(BlasterButton)`
  border-radius: 0;
  border-bottom: 0;
  height: 3.6rem;
  width: ${props => props.width};
  padding-top: 0;
  padding-bottom: 0;
  margin-left: -1px;
  line-height: 0;
  &.active {
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 4px 4px rgba(16, 22, 26, 0.2);
    background-image: none;
    background-color: ${props => props.theme.colors.gray200};
    z-index: 1;
  }
  font-size: ${props => props.theme.fontSizes[1]};
`;

const AnnotationCount = styled.span`
  color: ${props => props.theme.colors.gray900};
  font-size: ${props => props.theme.fontSizes[1]};
  margin-left: 3px;
  position: relative;
  top: 1px;
`;

interface Button {
  readonly title?: string;
  readonly annotationType: AnnotationType;
  readonly children: React.ReactNode;
}

const AnnotationButtons = ({
  loggedInUser,
  imageId,
  isAnnotationInProgress,
  hpfAnnotationCount,
  pointAnnotationCount,
  freehandAnnotationCount,
  hpfCursorVisible,
  setHpfCursorVisible,
  setShowLineLabels
}: Props) => {
  const dispatch = useAppDispatch();

  const annotation = useAppSelector(state => state.caseImageViewer.annotation);
  const hideAnnotations = useAppSelector(state => state.caseImageViewer.hideAnnotations);
  const imageWithAnnotations = useAppSelector(state => state.caseImageViewer.imageWithAnnotations);
  const hpfAnnotationClassesWithCount =
    imageWithAnnotations && "resource" in imageWithAnnotations
      ? imageWithAnnotations.resource.hpfAnnotationClassesWithCount.filter(awc => {
          return awc.annotationClass.enabled;
        })
      : null;

  const pointAnnotationClassesWithCount =
    imageWithAnnotations && "resource" in imageWithAnnotations
      ? imageWithAnnotations.resource.pointAnnotationClassesWithCount.filter(awc => {
          return awc.annotationClass.enabled;
        })
      : null;

  const freehandAnnotationClassesWithCount =
    imageWithAnnotations && "resource" in imageWithAnnotations
      ? imageWithAnnotations.resource.freehandAnnotationClassesWithCount.filter(awc => {
          return awc.annotationClass.enabled;
        })
      : null;
  const userCanCreateAnnotations = loggedInUser.can([
    RolePermissions.AP_ImageViewer_EditCreateAnnotations
  ]);

  const annotationTypesToShow = new Set([
    AnnotationType.Line,
    AnnotationType.Text,
    AnnotationType.HPF,
    AnnotationType.Ellipse,
    AnnotationType.Point,
    AnnotationType.Freehand
  ]);

  const [openAnnotationFlyout, setOpenAnnotationFlyout] = useState<
    AnnotationType.Point | AnnotationType.HPF | AnnotationType.Freehand | null
  >(null);
  function disableHpfCursor(): void {
    setHpfCursorVisible(false);
  }
  function enableHpfCursor(): void {
    setHpfCursorVisible(true);
    setShowLineLabels(false);
  }
  useEffect(() => {
    // Make sure to close the open menu if annotation is cancelled elsewhere (eg. via ESC key)
    if (!isAnnotationInProgress) {
      setOpenAnnotationFlyout(null);
    }
  }, [isAnnotationInProgress]);
  const cancel = () => {
    // Always close the menu whenever we stop annotating to avoid a situation where the menu is open
    // but no annotation class is selected
    setOpenAnnotationFlyout(null);
    dispatch(cancelImageAnnotation());
  };

  const firstHpfAnnotationClass =
    hpfAnnotationClassesWithCount && hpfAnnotationClassesWithCount.map(p => p.annotationClass)[0];
  const selectedHpfAnnotationClass =
    annotation.data && "hpfAnnotationClass" in annotation.data.selectedAnnotationType
      ? annotation.data.selectedAnnotationType.hpfAnnotationClass
      : null;

  const firstPointAnnotationClass =
    pointAnnotationClassesWithCount &&
    pointAnnotationClassesWithCount.map(p => p.annotationClass)[0];
  const selectedPointAnnotationClass =
    annotation.data && "pointAnnotationClass" in annotation.data.selectedAnnotationType
      ? annotation.data.selectedAnnotationType.pointAnnotationClass
      : null;
  const firstFreehandAnnotationClass =
    freehandAnnotationClassesWithCount &&
    freehandAnnotationClassesWithCount.map(p => p.annotationClass)[0];
  const selectedFreehandAnnotationClass =
    annotation.data && "freehandAnnotationClass" in annotation.data.selectedAnnotationType
      ? annotation.data.selectedAnnotationType.freehandAnnotationClass
      : null;

  const buttons: ReadonlyArray<Button> = [
    {
      title: "Measurement",
      annotationType: AnnotationType.Line,
      children: <Icon name="ruler" />
    },
    {
      title: "Text",
      annotationType: AnnotationType.Text,
      children: <Icon name="text" />
    },
    {
      title: "Ellipse",
      annotationType: AnnotationType.Ellipse,
      children: <Icon name="ellipse" />
    },
    hpfAnnotationClassesWithCount && hpfAnnotationClassesWithCount.length
      ? {
          annotationType: AnnotationType.HPF,
          children: (
            <AnnotationClassesButton
              annotationType={AnnotationType.HPF}
              isShown={openAnnotationFlyout === AnnotationType.HPF}
              annotationClassesWithCount={hpfAnnotationClassesWithCount}
              selectedAnnotationClass={selectedHpfAnnotationClass}
              userCanCreateAnnotations={userCanCreateAnnotations}
              onClose={() => {
                setOpenAnnotationFlyout(null);
                disableHpfCursor();
                userCanCreateAnnotations && cancel();
              }}
              onSelectAnnotationClass={selectedHpfAnnotationClass => {
                const msg = beginDroppingAnnotation({
                  selectedAnnotationType: {
                    type: AnnotationType.HPF,
                    hpfAnnotationClass: { ...selectedHpfAnnotationClass }
                  },
                  imageId: imageId
                });
                enableHpfCursor();
                userCanCreateAnnotations && dispatch(msg);
              }}
            />
          )
        }
      : {
          title: "HPF",
          annotationType: AnnotationType.HPF,
          children: (
            <>
              <Icon name="circle" />
              <AnnotationCount>{hpfAnnotationCount}</AnnotationCount>
            </>
          )
        },
    pointAnnotationClassesWithCount && pointAnnotationClassesWithCount.length
      ? {
          annotationType: AnnotationType.Point,
          children: (
            <AnnotationClassesButton
              annotationType={AnnotationType.Point}
              isShown={openAnnotationFlyout === AnnotationType.Point}
              annotationClassesWithCount={pointAnnotationClassesWithCount}
              selectedAnnotationClass={selectedPointAnnotationClass}
              userCanCreateAnnotations={userCanCreateAnnotations}
              onClose={() => {
                setOpenAnnotationFlyout(null);
                userCanCreateAnnotations && cancel();
              }}
              onSelectAnnotationClass={selectedPointAnnotationClass => {
                disableHpfCursor();
                userCanCreateAnnotations &&
                  dispatch(
                    beginAnnotation({
                      selectedAnnotationType: {
                        type: AnnotationType.Point,
                        pointAnnotationClass: selectedPointAnnotationClass
                      },
                      imageId: imageId
                    })
                  );
              }}
            />
          )
        }
      : {
          title: "Point",
          annotationType: AnnotationType.Point,
          children: (
            <>
              <Icon name="bullseye" />
              <AnnotationCount>{pointAnnotationCount}</AnnotationCount>
            </>
          )
        },
    freehandAnnotationClassesWithCount && freehandAnnotationClassesWithCount.length
      ? {
          annotationType: AnnotationType.Freehand,
          children: (
            <AnnotationClassesButton
              annotationType={AnnotationType.Freehand}
              isShown={openAnnotationFlyout === AnnotationType.Freehand}
              annotationClassesWithCount={freehandAnnotationClassesWithCount}
              selectedAnnotationClass={selectedFreehandAnnotationClass}
              userCanCreateAnnotations={userCanCreateAnnotations}
              onClose={() => {
                setOpenAnnotationFlyout(null);
                userCanCreateAnnotations && cancel();
              }}
              onSelectAnnotationClass={selectedFreehandAnnotationClass => {
                disableHpfCursor();
                userCanCreateAnnotations &&
                  dispatch(
                    beginAnnotation({
                      selectedAnnotationType: {
                        type: AnnotationType.Freehand,
                        freehandAnnotationClass: selectedFreehandAnnotationClass
                      },
                      imageId: imageId
                    })
                  );
              }}
            />
          )
        }
      : {
          title: "Freehand",
          annotationType: AnnotationType.Freehand,
          children: (
            <>
              <Icon name="polygon" />
              <AnnotationCount>{freehandAnnotationCount}</AnnotationCount>
            </>
          )
        }
  ];
  function annotationClassProps<
    T extends AnnotationType.Point | AnnotationType.HPF | AnnotationType.Freehand
  >(
    annotationType: T,
    firstAnnotationClass: T extends AnnotationType.Point
      ? AnnotationClass<"POINT">
      : T extends AnnotationType.HPF
      ? AnnotationClass<"HPF">
      : AnnotationClass<"FREEHAND">,
    isCurrentAnnotationSelectedType: boolean
  ) {
    return {
      style: { padding: "4px 10px 0" },
      width: "auto",
      "data-tooltip": true,
      onClick: () => {
        disableHpfCursor();
        setShowLineLabels(false);
        if (isAnnotationInProgress) {
          // Cancel any in-progress annotations as appropriate
          cancel();
        }
        if (!isCurrentAnnotationSelectedType) {
          // Show flyout (even users who can see annotations but can't create
          // them, eg. ISCs, need to be able to see annotations classes and their
          // counts).
          const isHpf = annotationType === AnnotationType.HPF;
          setOpenAnnotationFlyout(openAnnotationFlyout === annotationType ? null : annotationType);
          if (userCanCreateAnnotations) {
            // Start new annotation (by cancelling and re-starting only if the
            // selected annotation type has changed, we can both un-toggle and
            // toggle between menus seamlessly).
            if (isHpf) {
              enableHpfCursor();
              dispatch(
                beginDroppingAnnotation({
                  selectedAnnotationType: {
                    type: AnnotationType.HPF,
                    hpfAnnotationClass: firstAnnotationClass as AnnotationClass<"HPF">
                  },
                  imageId: imageId
                })
              );
            } else {
              disableHpfCursor();
              dispatch(
                beginAnnotation({
                  selectedAnnotationType: isHpf
                    ? {
                        type: AnnotationType.HPF,
                        hpfAnnotationClass: firstAnnotationClass as AnnotationClass<"HPF">
                      }
                    : annotationType === AnnotationType.Point
                    ? {
                        type: AnnotationType.Point,
                        pointAnnotationClass: firstAnnotationClass as AnnotationClass<"POINT">
                      }
                    : {
                        type: AnnotationType.Freehand,
                        freehandAnnotationClass: firstAnnotationClass as AnnotationClass<"FREEHAND">
                      },
                  imageId: imageId
                })
              );
            }
          } else {
            disableHpfCursor();
          }
        } else {
          disableHpfCursor();
        }
      },
      className: openAnnotationFlyout === annotationType ? "active" : null
    };
  }

  return (
    <AnnotationButtonsStyled>
      {buttons
        .filter(({ annotationType }) => annotationTypesToShow.has(annotationType))
        .map(({ title, annotationType, children }) => {
          const isClassAnnotationType =
            annotationType === AnnotationType.HPF ||
            annotationType === AnnotationType.Point ||
            annotationType === AnnotationType.Freehand;
          const isCurrentAnnotationSelectedType =
            annotation.data !== null &&
            annotation.data.selectedAnnotationType.type === annotationType;
          return (
            <AnnotationButton
              key={annotationType}
              title={title}
              disabled={hideAnnotations}
              {...(annotationType === AnnotationType.HPF && firstHpfAnnotationClass
                ? // HPF annotation and classes are configured
                  annotationClassProps(
                    AnnotationType.HPF,
                    firstHpfAnnotationClass,
                    isCurrentAnnotationSelectedType
                  )
                : annotationType === AnnotationType.Point && firstPointAnnotationClass
                ? // Point annotation and classes are configured
                  annotationClassProps(
                    AnnotationType.Point,
                    firstPointAnnotationClass,
                    isCurrentAnnotationSelectedType
                  )
                : annotationType === AnnotationType.Freehand && firstFreehandAnnotationClass
                ? // Freehand annotation and classes are configured
                  annotationClassProps(
                    AnnotationType.Freehand,
                    firstFreehandAnnotationClass,
                    isCurrentAnnotationSelectedType
                  )
                : // No classes are configured for this type of annotation. This means either the
                  // annotation type doesn't take annotation classes or it does take them but there
                  // are none configured. In the case of annotations which could have classes
                  // configured but do not, the fallback behavior is to just count up the total
                  // without bucketing by class.
                  {
                    pr: isClassAnnotationType ? "1rem" : "",
                    pl: isClassAnnotationType ? "1rem" : "",
                    width: isClassAnnotationType ? "auto" : "3.6rem",
                    onClick: () => {
                      if (annotationType === AnnotationType.HPF) {
                        if (hpfCursorVisible) {
                          disableHpfCursor();
                        } else {
                          enableHpfCursor();
                          setShowLineLabels(false);
                        }
                      } else if (annotationType === AnnotationType.Line) {
                        if (isCurrentAnnotationSelectedType) {
                          setShowLineLabels(false);
                        } else {
                          setShowLineLabels(true);
                        }
                        disableHpfCursor();
                      } else {
                        setShowLineLabels(false);
                        disableHpfCursor();
                      }
                      const selectedAnnotation =
                        annotationType === AnnotationType.HPF
                          ? {
                              type: AnnotationType.HPF,
                              hpfAnnotationClass: null
                            }
                          : annotationType === AnnotationType.Point
                          ? {
                              type: AnnotationType.Point,
                              pointAnnotationClass: null
                            }
                          : annotationType === AnnotationType.Freehand
                          ? {
                              type: AnnotationType.Freehand,
                              freehandAnnotationClass: null
                            }
                          : { type: annotationType };
                      if (userCanCreateAnnotations) {
                        isAnnotationInProgress && cancel();
                        if (!isCurrentAnnotationSelectedType) {
                          dispatch(
                            beginAnnotation({
                              selectedAnnotationType: selectedAnnotation as SelectedAnnotationType,
                              imageId: imageId
                            })
                          );
                        }
                      }
                    },
                    className: isCurrentAnnotationSelectedType ? "active" : null
                  })}
            >
              {children}
            </AnnotationButton>
          );
        })}
    </AnnotationButtonsStyled>
  );
};

export default AnnotationButtons;
