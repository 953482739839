import React from "react";
import { Callout, Intent } from "@blasterjs/core";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import ImageViewer from "../components/ImageViewer";
import InfoSidebarCase from "../components/InfoSidebarCase";
import TopBarCase from "../components/TopBarCase";
import Content from "../components/Content";
import { useAppDispatch, useAppSelector } from "../hooks";
import { caseFetch } from "../slices/caseImageViewer";
import { studyFetchRequest } from "../slices/studyConfiguration";
import { RolePermissions, userIsAdminOrReader } from "../permissions";

const Case = () => {
  const params = useParams();
  const id = params.id || "no-id";
  const studyId = params.studyId || "no-study-id";

  const dispatch = useAppDispatch();

  const histoCase = useAppSelector(state => state.caseImageViewer.histoCase);
  const selectedImage = useAppSelector(state => state.caseImageViewer.imageWithAnnotations);
  const config = useAppSelector(state => state.config.config);
  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  useEffect(() => {
    dispatch(caseFetch(id));
    dispatch(studyFetchRequest(studyId));
  }, [id]);

  const isUserAdminOrReader = userIsAdminOrReader(
    ("resource" in loggedInUser && loggedInUser.resource) || null
  );

  const userCanDeleteAnnotations = isUserAdminOrReader;

  const errorText =
    "errorMessage" in config
      ? config.errorMessage
      : histoCase && "errorMessage" in histoCase
      ? histoCase.errorMessage
      : selectedImage && "errorMessage" in selectedImage
      ? selectedImage.errorMessage
      : null;

  const content =
    "resource" in config &&
    histoCase !== null &&
    "resource" in histoCase &&
    (selectedImage === null || "resource" in selectedImage) &&
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageViewer_ViewimageViewer]) ? (
      <ImageViewer
        imageWithAnnotations={selectedImage === null ? selectedImage : selectedImage.resource}
        tileServerLocation={config.resource.tileServerLocation}
        topBar={<TopBarCase histoCase={histoCase.resource.caseWithStatus} />}
        sidebar={
          "resource" in loggedInUser &&
          (loggedInUser.resource.can([RolePermissions.AP_CaseViewer_ViewSidebar]) ||
            loggedInUser.resource.isUploader()) ? (
            <InfoSidebarCase
              histoCase={histoCase.resource}
              selectedImage={selectedImage === null ? null : selectedImage.resource.imageAndQuery}
              userCanDeleteAnnotations={userCanDeleteAnnotations}
            />
          ) : null
        }
      />
    ) : errorText ? (
      <Callout intent={Intent.DANGER}>{errorText}</Callout>
    ) : null;
  return (
    <Content
      isLoading={
        (histoCase !== null && "isPending" in histoCase) ||
        "isPending" in config ||
        (selectedImage !== null && "isPending" in selectedImage)
      }
    >
      {content}
    </Content>
  );
};

export default Case;
