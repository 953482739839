import { isEqual } from "lodash";
import { RolePermissions } from "./permissions";

export interface ZoomDefinition {
  readonly mag: string;
  readonly zoom: number;
  readonly overzoomed: boolean;
}

type LatLng = [number, number];

// NOTE: The official GeoJSON spec (see `geojson` package) allows for an optional third element in
// the lat/long tuple for elevation which we don't expect or want, so we have analogous minimal
// interfaces defined here. The `geojson` types also define `coordinates` as `number[]` and not as
// tuples which is less precise and thus a bit harder to work with.
export interface Polygon {
  readonly type: "Polygon";
  readonly coordinates: ReadonlyArray<ReadonlyArray<LatLng>>;
}

export interface Line {
  readonly type: "LineString";
  readonly coordinates: ReadonlyArray<LatLng>;
}

export interface Point {
  readonly type: "Point";
  readonly coordinates: LatLng;
  readonly parent: string | null;
}

export function userCanUpload(role: RoleName | null): boolean {
  return role === null
    ? false
    : [RoleName.LabTech, RoleName.ISC, RoleName.StudyAdmin].includes(role);
}

export function userIsAdminOrIsc(role: RoleName | null): boolean {
  return role === null ? false : [RoleName.StudyAdmin, RoleName.ISC].includes(role);
}

export function userIsAdminOrReader(role: RoleName | null): boolean {
  return role === null ? false : [RoleName.StudyAdmin, RoleName.CR].includes(role);
}

export function userLabel(user: User): string {
  return user.firstName && user.lastName
    ? `${user.firstName} ${user.lastName} (${user.username})`
    : user.username;
}

export function userInStudyLabel(user: UserInStudy): string {
  return user.firstName && user.lastName
    ? `${user.firstName} ${user.lastName} (${user.username})`
    : user.username;
}

export function studyLabel(study: Study): string {
  return study.name;
}

export function roleLabel(role: Role): string {
  return role.description;
}

export enum RoleName {
  SystemAdmin = "SystemAdmin",
  StudyAdmin = "StudyAdmin",
  ISC = "ISC",
  DM = "DM",
  PM = "PM",
  Sponsor = "SPONSOR",
  Uploader = "UPLOADER",
  CR = "CR",
  LabTech = "LabTech",
  Site = "Site",
  NOTO = "NOTO",
  LIS = "LIS"
}

export const QueryWithRoles = [
  RoleName.ISC,
  RoleName.DM,
  RoleName.PM,
  RoleName.Sponsor,
  RoleName.LabTech,
  RoleName.Uploader,
  RoleName.CR
];

export function formatRoleName(roleName: RoleName): string {
  switch (roleName) {
    case RoleName.SystemAdmin:
      return "System Admin";
    case RoleName.StudyAdmin:
      return "Study Admin";
    case RoleName.ISC:
      return "ISC";
    case RoleName.DM:
      return "DM";
    case RoleName.PM:
      return "PM";
    case RoleName.Sponsor:
      return "Sponsor";
    case RoleName.Uploader:
      return "Uploader";
    case RoleName.CR:
      return "CR";
    case RoleName.LabTech:
      return "LabTech";
    case RoleName.Site:
      return "Site";
    case RoleName.NOTO:
      return "NOTO";
    case RoleName.LIS:
      return "LIS";
    default:
      const exhaustive: never = roleName;
      return exhaustive;
  }
}

export interface Role {
  readonly id: string;
  readonly name: RoleName;
  readonly displayName: string;
  readonly description: string;
  readonly roleType: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export type Roles = ReadonlyArray<Role>;

export interface User {
  readonly id: string;
  readonly username: string;
  readonly lastLoginDt: Date | null;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly organizationId: string | null;
  readonly registered: boolean;
  readonly workAsRole: Role | null;
  readonly actions: ReadonlyArray<RolePermissions> | null;
  readonly isSystemAdmin: boolean | null;
}

export interface UserSummary {
  readonly id: string;
  readonly username: string;
  readonly lastLoginDt: Date | null;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly registered: boolean;
  readonly organizationName: string | null;
  readonly studyCount: number;
}

export interface UserInStudy {
  readonly id: string;
  readonly username: string;
  readonly lastLoginDt: Date | null;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly organizationId: string | null;
  readonly registered: boolean;
  readonly roleName: string;
  readonly organizationName: string | null;
}

export type Users = ReadonlyArray<User>;
export type UserSummaries = ReadonlyArray<UserSummary>;
export type UsersInStudy = ReadonlyArray<UserInStudy>;

export interface Organization {
  readonly id: string;
  readonly name: string;
  readonly groupEmail: string | null;
}
export type Organizations = ReadonlyArray<Organization>;

export interface StudyAccess {
  readonly studyId: UUID;
  readonly studyName: string;
  readonly roleId: UUID;
  readonly roleName: string;
}
export type StudiesAccess = ReadonlyArray<StudyAccess>;

export interface ReaderStudyStats {
  readonly reader: User;
  readonly numReadCases: number;
  readonly numTotalCases: number;
}

export type ReadersStudyStats = ReadonlyArray<ReaderStudyStats>;

export type UUID = string;
export type StudyId = string;
export type UserId = string;

export type SignedURLs = ReadonlyArray<string>;

export enum Indication {
  CrohnsDisease = "CROHNS_DISEASE",
  UlcerativeColitis = "ULCERATIVE_COLITIS",
  EosinophilicEsophagitis = "EOSINOPHILIC_ESOPHAGITIS",
  NonalcoholicSteatohepatitis = "NONALCOHOLIC_STEATOHEPATITIS",
  Pouchitis = "POUCHITIS",
  CeliacDisease = "CELIAC_DISEASE",
  PrimarySclerosingCholangitis = "PRIMARY_SCLEROSING_CHOLANGITIS",
  EosinophilicDuodenitis = "EOSINOPHILIC_DUODENITIS",
  EosinophilicGastroenteritis = "EOSINOPHILIC_GASTROENTERITIS"
}

export function formatIndication(indication: Indication): string {
  switch (indication) {
    case Indication.CrohnsDisease:
      return "Crohn's Disease (CD)";
    case Indication.UlcerativeColitis:
      return "Ulcerative Colitis (UC)";
    case Indication.EosinophilicEsophagitis:
      return "Eosinophilic Esophagitis (EoE)";
    case Indication.NonalcoholicSteatohepatitis:
      return "Nonalcoholic Steatohepatitis (NASH)";
    case Indication.Pouchitis:
      return "Pouchitis";
    case Indication.CeliacDisease:
      return "Celiac Disease";
    case Indication.PrimarySclerosingCholangitis:
      return "Primary Sclerosing Cholangitis (PSC)";
    case Indication.EosinophilicDuodenitis:
      return "Eosinophilic Duodenitis (EoD)";
    case Indication.EosinophilicGastroenteritis:
      return "Eosinophilic Gastroenteritis (EG)";
    default:
      const exhaustive: never = indication;
      return exhaustive;
  }
}

export enum Modality {
  Histopathology = "HISTOPATHOLOGY",
  Endoscopy = "ENDOSCOPY",
  Immunohistochemistry = "IMMUNOHISTOCHEMISTRY"
}

export function formatModality(modality: Modality): string {
  switch (modality) {
    case Modality.Histopathology:
      return "Histopathology";
    case Modality.Endoscopy:
      return "Endoscopy";
    case Modality.Immunohistochemistry:
      return "Immunohistochemistry";
    default:
      const exhaustive: never = modality;
      return exhaustive;
  }
}

export interface Study {
  readonly id: StudyId;
  readonly name: string;
  readonly sponsor: string | null;
  readonly protocolIds: ReadonlyArray<string>;
  readonly visitIds: ReadonlyArray<string>;
  readonly indications: ReadonlyArray<Indication>;
  readonly segments: number;
  readonly anatomicalSegments: ReadonlyArray<string>;
  readonly modality: ReadonlyArray<Modality>;
  readonly onHold: boolean;
  readonly onHoldReason: string | null;
  readonly createdAt: Date;
}

export type Studies = ReadonlyArray<Study>;

export interface StudyNoUsers {
  readonly study: Study;
}

export interface StudyAdminView {
  readonly study: Study;
  readonly uploaders: UsersInStudy;
  readonly readers: UsersInStudy;
  readonly hpfAnnotationClasses: HpfAnnotationClassesWithCount;
  readonly pointAnnotationClasses: PointAnnotationClassesWithCount;
  readonly freehandAnnotationClasses: FreehandAnnotationClassesWithCount;
}

export type StudyView = StudyAdminView | StudyNoUsers;

export interface ReportStudyData {
  readonly id: StudyId;
  readonly name: string;
}

export interface DateFilter {
  readonly start?: Date | undefined | "invalid";
  readonly end?: Date | undefined | "invalid";
}

export interface ReportUserData {
  readonly id: UserId;
  readonly name: string;
}

export interface ReaderStudyListView {
  readonly studyView: StudyNoUsers;
  readonly numberOfAssignedReadCases: number;
  readonly numberOfAssignedUnreadCases: number;
}

export interface UploaderStudyListView {
  readonly studyView: StudyNoUsers;
  readonly numberOfQueriesOpen: number;
}

export interface AdminStudyListView {
  readonly studyView: StudyAdminView;
  readonly mostRecentUploadAt: Date | null;
  readonly numberOfCasesNeedingQC: number;
  readonly numberOfQueriesAnswered: number;
  readonly numberOfQueriesOpen: number;
  readonly numberOfUnassignedImages: number;
}

export type StudyListView = ReaderStudyListView | AdminStudyListView | UploaderStudyListView;
export type StudyListViews = ReadonlyArray<StudyListView>;

export interface Config {
  readonly ssoLoginUrl: string;
  readonly ssoLogoutUrl: string;
  readonly tileServerLocation: string;
}

interface BaseAnnotation {
  readonly id: string;
  readonly createdAt: Date;
  readonly userId: string;
  readonly imageId: string;
  readonly annotationType: string;
}

export interface BasePointAnnotation extends BaseAnnotation {
  readonly geometry: Point;
}

export interface BaseLineAnnotation extends BaseAnnotation {
  readonly geometry: Line;
}

export type EllipseAnnotation = BasePointAnnotation & {
  readonly radiusX: number;
  readonly radiusY: number;
  readonly tilt: number;
  readonly text: string | null;
};

export type TextAnnotation = BasePointAnnotation & {
  readonly text: string;
};

export type PointAnnotation = BasePointAnnotation & {
  readonly parent: string | null;
  readonly weight: number | null;
} & AnnotationColor;

//export type HPFAnnotation = BasePointAnnotation & {
//  readonly radius: number;
//  readonly text: string | null;
//  //readonly points: ReadonlyArray<PointAnnotation>;
//} & AnnotationColor;

export interface HPFAnnotation {
  readonly id: string;
  readonly createdAt: Date;
  readonly userId: string;
  readonly imageId: string;
  readonly annotationType: string;
  readonly geometry: Point;
  readonly radius: number;
  readonly weight: number | null;
  readonly text: string | null;
  readonly color: string | null;
}

interface AnnotationColor {
  readonly color: string | null;
}

export type LineAnnotation = BaseLineAnnotation & {
  readonly length: number | null;
};

export type FreehandAnnotation = BaseAnnotation & {
  readonly geometry: Polygon;
} & AnnotationColor;

export type Annotation =
  | LineAnnotation
  | EllipseAnnotation
  | TextAnnotation
  | HPFAnnotation
  | PointAnnotation
  | FreehandAnnotation;

export interface QueryStats {
  readonly numOpen: number;
  readonly numAnswered: number;
  readonly numClosed: number;
  readonly numOverdue: number;
}

export interface QueryViewRecord {
  readonly id: string;
  readonly objectType: QueryObjectType;
  readonly objectId: string;
  readonly studyId: string | null;
  readonly caseId: string | null;
  readonly organizationId: string | null;
  readonly assessmentType: QueryAssessmentType;
  readonly queryStatus: QueryStatus;
  readonly dynamicStatus: DynamicQueryStatus | null;
  readonly resolution: QueryResolutionType | null;
  readonly category: QueryRecordCategory | null;
  readonly categoryOtherText: string | null;
  readonly closeText: string | null;
  readonly resolutionText: string | null;
  readonly firstQueryReminderId: UUID | null;
  readonly lastQueryReminderId: UUID | null;
  readonly latestFollowUpOnDt: Date | null;
  readonly openedAt: Date;
  readonly openedByRoleId: UUID | null;
  readonly closedAt: Date | null;
  readonly closedBy: string | null;
  readonly updatedAt: Date | null;
}

export interface QueryData {
  readonly id: string;
  readonly objectType: QueryObjectType;
  readonly objectId: string;
  readonly studyId: string | null;
  readonly caseId: string | null;
  readonly organizationId: string | null;
  readonly assessmentType: QueryAssessmentType;
  readonly queryStatus: QueryStatus;
  readonly dynamicStatus: DynamicQueryStatus | null;
  readonly resolution: QueryResolutionType | null;
  readonly category: QueryRecordCategory | null;
  readonly categoryOtherText: string | null;
  readonly closeText: string | null;
  readonly resolutionText: string | null;
  readonly firstQueryReminderId: UUID | null;
  readonly lastQueryReminderId: UUID | null;
  readonly latestFollowUpOnDt: Date | null;
  readonly openedAt: Date;
  readonly closedAt: Date | null;
  readonly closedBy: string | null;
  readonly updatedAt: Date | null;
}

export interface QuerySearchResultData {
  readonly query: QueryData;
  readonly dynamicStatus: string | null;
  readonly studyName: string | null;
  readonly caseName: string | null;
  readonly histoProcedureId: string | null;
  readonly imageName: string | null;
  readonly openedByUsername: string;
  readonly queryWith: string | null;
  readonly queryReasonText: string | null;
}

export interface QuerySearchRecord {
  readonly id: string;
  readonly objectType: QueryObjectType;
  readonly objectId: string;
  readonly studyId: string | null;
  readonly caseId: string | null;
  readonly organizationId: string | null;
  readonly queryStatus: QueryStatus;
  readonly dynamicStatus: DynamicQueryStatus | null;
  readonly resolution: QueryResolutionType | null;
  readonly category: QueryRecordCategory | null;
  readonly categoryOtherText: string | null;
  readonly closeText: string | null;
  readonly resolutionText: string | null;
  readonly firstQueryReminderId: UUID | null;
  readonly lastQueryReminderId: UUID | null;
  readonly latestFollowUpOnDt: Date | null;
  readonly openedAt: Date;
  readonly openedByUsername: string;
  readonly openedByRoleId: UUID | null;
  readonly closedAt: Date | null;
  readonly closedBy: string | null;
  readonly studyName: string | null;
  readonly caseName: string | null;
  readonly histoProcedureId: string | null;
  readonly imageName: string | null;
  readonly updatedAt: Date | null;
  readonly queryWith: string | null;
  readonly queryReasonText: string | null;
}

export interface QueryDetailRecord {
  readonly id: UUID;
  readonly objectType: QueryObjectType;
  readonly objectId: UUID;
  readonly studyId: UUID | null;
  readonly caseId: UUID | null;
  readonly organizationId: UUID | null;
  readonly assessmentType: QueryAssessmentType;
  readonly queryStatus: QueryStatus;
  readonly dynamicStatus: DynamicQueryStatus | null;
  readonly resolution: QueryResolutionType | null;
  readonly category: QueryRecordCategory;
  readonly categoryOtherText: string | null;
  readonly closeText: string | null;
  readonly resolutionText: string | null;
  readonly firstQueryReminderId: UUID | null;
  readonly lastQueryReminderId: UUID | null;
  readonly latestFollowUpOnDt: Date | null;
  readonly withRoleId: UUID | null;
  readonly reasonOptionId: UUID | null;
  readonly childReasonOptionId: UUID | null;
  readonly openedAt: Date;
  readonly openedBy: string;
  readonly openedByRoleId: UUID | null;
  readonly closedAt: Date | null;
  readonly closedBy: string | null;
  readonly updatedAt: Date;
  readonly organizationName: string | null;
  readonly createdBy: string;
  readonly reasonText: string | null;
  readonly childReasonText: string | null;
  readonly resolutionOption: string | null;
  readonly resolvedBy: string | null;
  readonly resolvedAt: Date | null;
}

export type QueryRecord = QueryViewRecord | QuerySearchRecord;

export type QueryRecordsData = ReadonlyArray<QuerySearchResultData>;

export type QueryRecords = ReadonlyArray<QueryRecord>;
export type QuerySearchRecords = ReadonlyArray<QuerySearchRecord>;

export type QuerySearchDataRecords = ReadonlyArray<QuerySearchResultData>;

export interface QueryReminder {
  readonly id: string;
  readonly queryId: string;
  readonly followUpOnDt: Date;
  readonly remindedOnDt: Date | null;
  readonly remindedBy: string | null;
  readonly createdAt: Date;
  readonly createdBy: string;
  readonly updatedAt: Date;
}

export enum QueryAssessmentType {
  Pre = "PRE",
  Post = "POST"
}

export enum QueryResolutionType {
  Resolved = "RESOLVED",
  Unresolvable = "UNRESOLVABLE"
}

export function formatQueryResolutionType(queryResolution: QueryResolutionType): string {
  switch (queryResolution) {
    case QueryResolutionType.Resolved:
      return "Resolved";
    case QueryResolutionType.Unresolvable:
      return "Unresolvable";
    default:
      const exhaustive: never = queryResolution;
      return exhaustive;
  }
}

export interface QueryMetadataRole {
  readonly id: UUID;
  readonly name: string;
  readonly description: string;
  readonly roleType: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface QueryMetadataReason {
  readonly roleId: UUID;
  readonly values: Array<QueryMetadataReasonValue>;
}

export interface QueryMetadataReasonValue {
  readonly reasonOptionId: UUID;
  readonly reasonOptionValueId: UUID;
  readonly reasonText: string;
  readonly children: Array<QueryMetadataReasonDropdownValue> | null;
}

export interface QueryMetadataReasonDropdownValue {
  readonly id: UUID;
  readonly parentId: UUID;
  readonly reasonText: string;
}

export interface QueryMetadata {
  readonly roles: Array<Role>;
  readonly caseReasons: Array<QueryMetadataReason>;
  readonly imageReasons: Array<QueryMetadataReason>;
}

export interface QueryComment {
  readonly queryId: UUID;
  readonly text: string;
}

export interface QueryDetailComment {
  readonly id: UUID;
  readonly queryId: UUID;
  readonly commentText: string;
  readonly createdAt: Date;
  readonly commentBy: UUID;
  readonly username: string;
}

export interface QueryDetail {
  readonly query: QueryDetailRecord;
  readonly reminders: Array<QueryReminder>;
  readonly comments: Array<QueryDetailComment>;
}

export type QueryDetails = ReadonlyArray<QueryDetail>;

export enum ProcessingStatusType {
  Holding = "HOLDING",
  Pending = "PENDING",
  Submitted = "SUBMITTED",
  Success = "SUCCESS",
  Fail = "FAIL"
}

export enum ImageStatusType {
  Processing = "PROCESSING",
  FailProcessing = "FAIL_PROCESSING",
  DataIncomplete = "DATA_INCOMPLETE",
  StudyAssignmentPending = "STUDY_ASSIGNMENT_PENDING",
  DuplicateImage = "DUPLICATE_IMAGE",
  CaseAssignmentPending = "CASE_ASSIGNMENT_PENDING",
  LabQcPending = "LAB_QC_PENDING",
  IscQcPending = "ISC_QC_PENDING",
  ReadyForReading = "READY_FOR_READING",
  Complete = "COMPLETE",
  Archived = "ARCHIVED"
}

export function formatImageStatusType(imageStatus: ImageStatusType | null): string {
  switch (imageStatus) {
    case ImageStatusType.Processing:
      return "Processing";
    case ImageStatusType.FailProcessing:
      return "Failed Processing";
    case ImageStatusType.DataIncomplete:
      return "Data Incomplete";
    case ImageStatusType.StudyAssignmentPending:
      return "Study Assignment Pending";
    case ImageStatusType.DuplicateImage:
      return "Duplicate Image";
    case ImageStatusType.CaseAssignmentPending:
      return "Case Assignment Pending";
    case ImageStatusType.LabQcPending:
      return "Lab QC Pending";
    case ImageStatusType.IscQcPending:
      return "ISC QC Pending";
    case ImageStatusType.ReadyForReading:
      return "Ready For Reading";
    case ImageStatusType.Complete:
      return "Complete";
    case ImageStatusType.Archived:
      return "Archived";
    default:
      return "";
  }
}

export interface SampleDataImportResults {
  readonly message: string;
  readonly numRowsUpdated: number;
  readonly numFieldsUpdated: number;
  readonly numRowsFailed: number;
  readonly s3SampleDataResponse: string;
}

export interface BaseImage {
  readonly id: UUID;
  readonly name: string;
  readonly s3Key: string;
  readonly uploadedAt: Date;
  readonly studyId: UUID | null;
  readonly uploaderId: UUID;
  readonly caseId: UUID | null;
  readonly processingStatus: ProcessingStatusType | null;
  readonly status: ImageStatusType;
  readonly accessionNumber: string | null;
  readonly biopsyLocation: string | null;
  readonly zStackSize: number | null;
  readonly lastImageComment: string | null;
  readonly lastLabQueryId: UUID | null;
  readonly ignored: boolean;
}

export type UnprocessedImage = BaseImage;

export type ProcessedImage = BaseImage & {
  readonly cogKey: string;
  readonly magnifications: ReadonlyArray<number>;
  readonly extent: Polygon;
  readonly pixelSize: number;
  readonly physicalResolution: number;
  readonly maxTmsZoom: number;
};

export type Image = UnprocessedImage | ProcessedImage;

export type ImageManagementRecord = {
  readonly id: UUID | null;
  readonly name: string | null;
  readonly s3Key: string | null;
  readonly uploadedAt: Date | null;
  readonly studyId: UUID | null;
  readonly uploaderId: UUID | null;
  readonly caseId: UUID | null;
  readonly processingStatus: ProcessingStatusType | null;
  readonly status: ImageStatusType | null;
  readonly statusMessage: string | null;
  readonly accessionNumber: string | null;
  readonly biopsyLocation: string | null;
  readonly zStackSize: number | null;
  readonly lastImageComment: string | null;
  readonly lastLabQueryId: UUID | null;
  readonly ignored: boolean | null;

  readonly labImageId: UUID | null;
  readonly organizationId: UUID | null;
  readonly machineReadableBarcode: string | null;
  readonly appClientId: string | null;
  readonly imageFilename: string | null;
  readonly transactionId: UUID | null;
  readonly labS3Key: string | null;
  readonly labAccessionNumber: string | null;
  readonly fcidAccessionNumber: string | null;
  readonly stainType: string | null;
  readonly sponsor: string | null;
  readonly protocolId: string | null;
  readonly siteId: string | null;
  readonly subjectId: string | null;
  readonly visitId: string | null;
  readonly specimenCollectionDate: string | null;
  readonly anatomicalSegment: string | null;
  readonly qaComments: string | null;
  readonly labImageCreatedAt: Date | null;
  readonly updatedAt: Date | null;

  readonly organizationName: string | null;
  readonly studyName: string | null;
  readonly isStudyMatched: boolean;
  readonly isCaseMatched: boolean;
  readonly histoProcedureId: string | null;
  readonly endoProcedureId: string | null;
  readonly inQuery: boolean | null;
};

export type ImageManagementRecords = ReadonlyArray<ImageManagementRecord>;

export type StudyAccessRecordView = {
  readonly studyId: UUID;
  readonly userId: UUID;
  readonly username: string;
  readonly roleId: UUID;
  readonly roleName: string;
};
export type StudyAccessRecordViews = ReadonlyArray<StudyAccessRecordView>;

export type ImageManagementCaseRecord = {
  readonly caseId: UUID | null;
  readonly procId: string;
  readonly histoProcedureId: string | null;
  readonly caseCreatedAt: Date;
  readonly caseUpdatedAt: Date;
  readonly studyId: UUID | null;
  readonly subjectId: string;
  readonly visitId: string;
  readonly siteId: string | null;
  readonly qc1By: UUID | null;
  readonly qc2By: UUID | null;
  readonly readBy: UUID | null;
  readonly qc1CompletedAt: Date | null;
  readonly qc2CompletedAt: Date | null;
  readonly readAt: Date | null;
  readonly caseStatusReverted: boolean;
  readonly status: CaseStatus;
  readonly statusUpdatedAt: Date | null;
  readonly statusUpdatedBy: UUID | null;
  readonly openQueries: number;

  readonly studyName: string;
  readonly imagesExpected: number;
  readonly totalImages: number;
  readonly crAssignments: ReadonlyArray<string>;

  readonly labTech: StudyAccessRecordViews;
  readonly iscQc: StudyAccessRecordViews;
};

export type ImageManagementCaseRecords = ReadonlyArray<ImageManagementCaseRecord>;

export enum DynamicQueryStatus {
  Answered = "ANSWERED",
  FollowUpRequired = "FOLLOWUPREQUIRED",
  FollowedUp = "FOLLOWEDUP",
  UpcomingDueDate = "UPCOMINGDUEDATE"
}
export function formatDynamicQueryStatus(status: DynamicQueryStatus | null): string {
  switch (status) {
    case DynamicQueryStatus.Answered:
      return "Answered";
    case DynamicQueryStatus.FollowUpRequired:
      return "Follow Up Required";
    case DynamicQueryStatus.FollowedUp:
      return "Followed Up";
    case DynamicQueryStatus.UpcomingDueDate:
      return "Upcoming Due Date";
    default:
      return "";
  }
}

export enum QueryRecordCategory {
  Blurry = "BLURRY",
  Incomplete = "INCOMPLETE",
  BadFilename = "BAD_FILENAME",
  Other = "OTHER",
  InternalWithDM = "INTERNAL_QUERY_WITH_DM",
  InternalWithPM = "INTERNAL_QUERY_WITH_PM",
  InternalWithCR = "INTERNAL_QUERY_WITH_CR",
  InternalOther = "INTERNAL_QUERY_OTHER"
}
export function formatQueryRecordCategory(queryCategory: QueryRecordCategory): string {
  switch (queryCategory) {
    case QueryRecordCategory.Blurry:
      return "Image blurry";
    case QueryRecordCategory.Incomplete:
      return "Image incomplete";
    case QueryRecordCategory.BadFilename:
      return "Incorrect filename/segment ID";
    case QueryRecordCategory.Other:
      return "Other";
    case QueryRecordCategory.InternalWithDM:
      return "Query with DM";
    case QueryRecordCategory.InternalWithPM:
      return "Query with PM";
    case QueryRecordCategory.InternalWithCR:
      return "Query with CR";
    case QueryRecordCategory.InternalOther:
      return "Other";
    default:
      const exhaustive: never = queryCategory;
      return exhaustive;
  }
}

export enum FixedQueryCategory {
  Blurry = "BLURRY",
  Incomplete = "INCOMPLETE",
  BadFilename = "BAD_FILENAME",
  InternalWithDM = "INTERNAL_QUERY_WITH_DM",
  InternalWithPM = "INTERNAL_QUERY_WITH_PM",
  InternalWithCR = "INTERNAL_QUERY_WITH_CR"
}

export enum OtherQueryCategory {
  Other = "OTHER"
}

export type QueryCategory = FixedQueryCategory | OtherQueryCategory;

export function formatQueryCategory(queryCategory: QueryCategory): string {
  switch (queryCategory) {
    case FixedQueryCategory.Blurry:
      return "Image blurry";
    case FixedQueryCategory.Incomplete:
      return "Image incomplete";
    case FixedQueryCategory.BadFilename:
      return "Incorrect filename/segment ID";
    case OtherQueryCategory.Other:
      return "Other";
    case FixedQueryCategory.InternalWithDM:
      return "Query with DM";
    case FixedQueryCategory.InternalWithPM:
      return "Query with PM";
    case FixedQueryCategory.InternalWithCR:
      return "Query with CR";
    default:
      const exhaustive: never = queryCategory;
      return exhaustive;
  }
}

export interface FixedQueryValue {
  readonly category: FixedQueryCategory;
}

export interface OtherQueryValue {
  readonly text: string;
}

export type QueryValue = FixedQueryValue | OtherQueryValue;

export enum UnresolvedQueryType {
  Open = "OPEN",
  PendingReview = "PENDING_REVIEW"
}

export enum ResolvedQueryType {
  Resolved = "RESOLVED"
}

export const QueryType = { ...UnresolvedQueryType, ...ResolvedQueryType };

export interface UnresolvedQuery {
  readonly value: QueryValue;
  readonly queryType: UnresolvedQueryType;
}

export interface ResolvedQuery {
  readonly value: QueryValue;
  readonly queryType: ResolvedQueryType;
  readonly queryResolution: QueryResolutionType;
}

export type Query = UnresolvedQuery | ResolvedQuery;

export const isResolvedQuery = (query: Query | null) =>
  query === null ? false : query.queryType === QueryType.Resolved;

export const isUnresolvedQuery = (query: Query | null) =>
  query === null
    ? false
    : query.queryType === QueryType.Open || query.queryType === QueryType.PendingReview;

export interface ImageAndQuery {
  readonly image: Image;
  readonly query: Query | null;
  readonly lastLabQuery: QueryRecord | null;
  readonly isShownToReader: boolean;
}

export type ProcessedImageAndQuery = ImageAndQuery & {
  readonly image: ProcessedImage;
};

export type SimpleCase = Pick<Case, "id" | "procId">;

export interface ImageNoCase {
  readonly imageAndQuery: ImageAndQuery;
}

export interface ImageWithCase {
  readonly imageAndQuery: ImageAndQuery;
  readonly simpleCase: SimpleCase | null;
}

export type ImageListView = ImageNoCase | ImageWithCase;
export type ImageListViews = ReadonlyArray<ImageListView>;

export interface ImageWithAnnotations {
  readonly imageAndQuery: ImageAndQuery;
  readonly study: Study | null;
  readonly annotations: ReadonlyArray<Annotation>;
  readonly hpfAnnotationCount: number;
  readonly hpfAnnotationClassesWithCount: HpfAnnotationClassesWithCount;
  readonly pointAnnotationCount: number;
  readonly pointAnnotationClassesWithCount: PointAnnotationClassesWithCount;
  readonly freehandAnnotationCount: number;
  readonly freehandAnnotationClassesWithCount: FreehandAnnotationClassesWithCount;
}

export type ProcessedImageWithAnnotations = ImageWithAnnotations & {
  readonly imageAndQuery: ProcessedImageAndQuery;
};

export type Images = ReadonlyArray<Image>;
export type ImageAndQueries = ReadonlyArray<ImageAndQuery>;

export enum QueryStatus {
  Open = "OPEN",
  Closed = "CLOSED"
}

export enum QueryObjectType {
  Case = "CASE",
  Image = "IMAGE"
}

export enum QueryWindowLocation {
  Case = "CASE",
  Image = "IMAGE"
}

export enum CaseStatus {
  PendingLabQC = "PENDING_LAB_QC",
  PendingQC = "PENDING_QC",
  Processed = "PROCESSED",
  Completed = "COMPLETED",
  OnHold = "ON_HOLD",
  Invalid = "INVALID",
  Archived = "ARCHIVED"
}
export function formatQueryStatus(status: QueryStatus): string {
  switch (status) {
    case QueryStatus.Open:
      return "Open";
    case QueryStatus.Closed:
      return "Closed";
    default:
      const exhaustive: never = status;
      return exhaustive;
  }
}

export interface ReaderCase {
  readonly id: UUID;
  readonly procId: string;
  readonly histoProcedureId: string | null;
  readonly createdAt: Date;
  readonly studyId: UUID;
  readonly status: CaseStatus;
}

export type AdminCase = ReaderCase & {
  readonly siteId: string | null;
  readonly subjectId: string;
  readonly visitId: string;
  readonly qc1By: string | null;
  readonly qc2By: string | null;
  readonly readBy: string | null;
  readonly prevWorkflowStatuses: ReadonlyArray<CaseStatus>;
  readonly lastCaseComment: string | null;
  readonly lastInternalQuery: QueryRecord | null;
  readonly openQueries: number;
};

export type Case = ReaderCase | AdminCase;

export interface CaseSummary {
  readonly label: string;
  readonly caseId: UUID;
  readonly caseStatus: CaseStatus;
  readonly subjectId: string;
  readonly visitId: string;
  readonly siteId: string | null;
}
export type CaseSummaries = ReadonlyArray<CaseSummary>;

export interface ReaderCaseWithImages {
  readonly caseWithStatus: ReaderCase;
  readonly images: Images;
}

export interface AdminCaseWithImagesAndReaders {
  readonly caseWithStatus: AdminCase;
  readonly images: Images;
  readonly imageQueries: ReadonlyArray<ReadonlyArray<ImageAndQuery>>;
  readonly readers: ReadersStudyStats;
}

export type CaseWithImages = ReaderCaseWithImages | AdminCaseWithImagesAndReaders;

export interface CaseAndCountsNonAdminView {
  readonly caseWithStatus: ReaderCase;
  readonly numberOfImages: number;
}

export type CaseAndCountsAdminView = {
  readonly caseWithStatus: AdminCase;
  readonly numberOfImages: number;
  readonly numberOfQueries: number;
  readonly qc1User: User | null;
  readonly qc2User: User | null;
  readonly assignedReaders: Users;
};

export type CaseAndCounts = CaseAndCountsNonAdminView | CaseAndCountsAdminView;
export type CasesAndCounts =
  | ReadonlyArray<CaseAndCountsAdminView>
  | ReadonlyArray<CaseAndCountsNonAdminView>;

export function formatCaseStatus(status: CaseStatus): string {
  switch (status) {
    case CaseStatus.PendingLabQC:
      return "Pending Lab QC";
    case CaseStatus.PendingQC:
      return "Pending QC";
    case CaseStatus.Processed:
      return "Processed";
    case CaseStatus.Completed:
      return "Completed";
    case CaseStatus.OnHold:
      return "On Hold";
    case CaseStatus.Archived:
      return "Archived";
    case CaseStatus.Invalid:
      return "—";
    default:
      const exhaustive: never = status;
      return exhaustive;
  }
}

export type MetadataImageType = "label" | "macro";

export type ImageData = string;

export interface MetadataImage<T extends MetadataImageType> {
  readonly metadataType: T;
  readonly imageData: ImageData;
}

export type AnnotationClassType = "HPF" | "POINT" | "FREEHAND";

// eslint-disable-next-line
export interface AnnotationClass<T extends AnnotationClassType> {
  readonly id: string;
  readonly name: string;
  readonly color: string;
  readonly sortOrder: number | null;
  readonly type: string;
  readonly enabled: boolean;
}

export type HpfAnnotationClass = AnnotationClass<"HPF">;
export type PointAnnotationClass = AnnotationClass<"POINT">;
export type FreehandAnnotationClass = AnnotationClass<"FREEHAND">;

export interface AnnotationClassWithCount<T extends AnnotationClassType> {
  readonly annotationClass: AnnotationClass<T>;
  readonly count: number;
}

export type HpfAnnotationClassWithCount = AnnotationClassWithCount<"HPF">;
export type PointAnnotationClassWithCount = AnnotationClassWithCount<"POINT">;
export type FreehandAnnotationClassWithCount = AnnotationClassWithCount<"FREEHAND">;

export type HpfAnnotationClasses = ReadonlyArray<HpfAnnotationClass>;
export type HpfAnnotationClassesWithCount = ReadonlyArray<HpfAnnotationClassWithCount>;

export type PointAnnotationClasses = ReadonlyArray<PointAnnotationClass>;
export type PointAnnotationClassesWithCount = ReadonlyArray<PointAnnotationClassWithCount>;

export type FreehandAnnotationClasses = ReadonlyArray<FreehandAnnotationClass>;
export type FreehandAnnotationClassesWithCount = ReadonlyArray<FreehandAnnotationClassWithCount>;

// NOTE: We nest the property under `value` so we can optionally include a reason for change when
// editing while keeping the shape of the data consistent regardless of whether creating or editing
export type NestValues<Type> = {
  readonly [Property in keyof Type]: { [P in Property as `value`]: Type[Property] };
};

export type AddReasonForChange<Type> = {
  readonly [Property in keyof Type]: Type[Property] & {
    readonly reasonForChange?: string;
  };
};

export interface UpdateValueWithReasonForChange<T> {
  readonly value: T;
  readonly reasonForChange: string;
}

export interface UpdateValueWithMaybeReasonForChange<T> {
  readonly value: T;
  readonly reasonForChange?: string;
}

/*
 * Filters the form to only include updated values.
 */
export function onlyUpdates<T extends object>(
  initialForm: Partial<T>,
  currentForm: Partial<T>
): Partial<T> {
  return Object.entries(currentForm).reduce((acc, [key, value]) => {
    return !isEqual(value, initialForm[key as keyof T] as typeof value)
      ? {
          ...acc,
          [key]: value
        }
      : acc;
  }, {});
}

export interface HasId {
  readonly id: UUID;
}

export interface PointAnno {
  readonly id: string;
  readonly name: string;
  readonly count: number;
  readonly color: string;
  readonly pointIds: string[];
}

export interface HpfAnno {
  readonly id: string;
  readonly name: string;
  readonly count: number;
  readonly color: String;
  readonly isOpen: boolean;
  readonly points?: ReadonlyArray<PointAnno>;
}

export interface Fragment {
  readonly name: string;
  readonly count: number;
  readonly color: String;
  readonly isOpen: boolean;
  readonly hpfs?: ReadonlyArray<HpfAnno>;
}

export interface ApiResponse {
  readonly success: boolean;
  readonly message: String;
}

export interface SampleDataSignedURL {
  readonly s3SampleDataKey: string;
  readonly fileName: string;
  readonly resultFileKey: string;
}

export interface SampleDataUploadResponse {
  readonly putSignedUrl: string;
  readonly sampleDataRequest: SampleDataSignedURL;
}

export enum QueryResponseOption {
  "FIELD_ADDRESSED" = "Field Addressed",
  "MANUAL_ENTRY_REQUIRED" = "Manual Entry Required",
  "UNRESOLVABLE" = "Unresolvable",
  "OTHER" = "Other"
}

export interface GetDownloadResponse {
  readonly url: string;
}
