import AnnotationPopup from "./AnnotationPopup";
import { LatLngExpression } from "leaflet";
import { Polygon } from "react-leaflet";
import React from "react";
import { UUID } from "../models";
import { AppDispatch } from "../store";

interface Props {
  readonly positions: ReadonlyArray<ReadonlyArray<LatLngExpression>>;
  readonly date: string;
  readonly annotationId: UUID;
  readonly color: string | null;
  readonly canDelete: boolean;
  readonly isClickable: boolean;
  dispatch: AppDispatch;
}

export const defaultColor = "#FFFF00";

const FreehandAnnotation = ({
  positions,
  date,
  annotationId,
  color,
  canDelete,
  dispatch
}: Props) => {
  const colorWithFallback = color || defaultColor;
  return (
    <Polygon
      positions={positions as LatLngExpression[][]}
      color={colorWithFallback}
      fillColor={colorWithFallback}
      fillOpacity={0.1}
    >
      <AnnotationPopup
        date={date}
        annotationId={annotationId}
        canDelete={canDelete}
        dispatch={dispatch}
      />
    </Polygon>
  );
};

export default FreehandAnnotation;
