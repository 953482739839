import { Polyline, PolylineProps, Tooltip } from "react-leaflet";

import AnnotationPopup from "./AnnotationPopup";
import React from "react";
import { UUID } from "../models";
import { AppDispatch } from "../store";

interface Props extends PolylineProps {
  readonly text: string;
  readonly date: string;
  readonly annotationId: UUID;
  readonly canDelete: boolean;
  readonly showLabel?: boolean;
  readonly lineLength: number | null;
  readonly maxZoom: number;
  dispatch: AppDispatch;
}

const LineAnnotation = ({
  positions,
  text,
  date,
  annotationId,
  canDelete,
  showLabel,
  dispatch
}: Props) => {
  return (
    <Polyline positions={positions} color={"#00FF00"} weight={5}>
      <AnnotationPopup
        text={text}
        date={date}
        annotationId={annotationId}
        canDelete={canDelete}
        dispatch={dispatch}
      />
      {showLabel === true ? (
        <Tooltip
          key={"tt" + annotationId.toString()}
          className="line-annotation-label"
          direction="center"
          offset={[0, 0]}
          permanent={true}
        >
          {text}
        </Tooltip>
      ) : (
        <></>
      )}
    </Polyline>
  );
};

export default LineAnnotation;
