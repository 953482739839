import qs from "qs";
import React, { useEffect } from "react";
import { Blaster } from "@blasterjs/core";
import { RouteProps } from "react-router";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";

import Case from "./Case";
import Content from "../components/Content";
import "../css/App.css";
import Image from "./Image";
import Login from "./Login";
import Queries from "./Queries";
import Reports from "./Reports";
import Studies from "./Studies";
import Study from "./Study";
import StudyConfiguration from "./StudyConfiguration";
import theme from "../theme";
import Users from "./Users";

import { Resource } from "../types";
import { useAppDispatch, useAppSelector } from "../hooks";
import { userFetch } from "../slices/auth";
import EditUser from "./EditUser";
import ImageManagement from "./ImageManagement";
import Welcome from "./Welcome";
import { LoggedInUser, RolePermissions } from "../permissions";
import AccessDenied from "./AccessDenied";

interface LoggedInUserRouteProps {
  readonly loggedInUser: Resource<LoggedInUser>;
  readonly requiredPermissions?: ReadonlyArray<RolePermissions>;
  readonly element: any;
}

function PermissionBasedRoute({
  loggedInUser,
  requiredPermissions,
  element
}: LoggedInUserRouteProps & RouteProps) {
  const location = useLocation();
  if (!("resource" in loggedInUser)) {
    if (location.pathname === "/login") {
      return <Login />;
    } else {
      const dest: string = `/login?redirect=${location.pathname}`;
      return <Navigate replace to={dest} />;
    }
  } else if (!loggedInUser.resource.user.registered) {
    return <Navigate replace to="/welcome" />;
  } else if (
    requiredPermissions &&
    requiredPermissions.length &&
    !loggedInUser.resource.can(requiredPermissions)
  ) {
    return <Navigate to="/accessDenied" />;
  } else {
    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true
    });
    return typeof queryParams.redirect === "string" ? (
      <Navigate replace to={queryParams.redirect} />
    ) : (
      element
    );
  }
}

const App = () => {
  const dispatch = useAppDispatch();

  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);
  const user = useAppSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(userFetch());
  }, []);

  return (
    <BrowserRouter>
      <Blaster theme={theme}>
        <div className="App">
          {"isPending" in user ? (
            <Content isLoading={true} />
          ) : (
            <>
              <Routes>
                <Route
                  path="/login"
                  element={
                    <PermissionBasedRoute
                      loggedInUser={loggedInUser}
                      requiredPermissions={[]}
                      element={<Navigate replace to="/welcome" />}
                    />
                  }
                />
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/accessDenied" element={<AccessDenied />} />
                <Route
                  path="/users/:id/:tab?"
                  element={
                    <PermissionBasedRoute
                      loggedInUser={loggedInUser}
                      requiredPermissions={[RolePermissions.UM_ViewUsersPage]}
                      element={<EditUser />}
                    />
                  }
                />
                <Route
                  path="/users"
                  element={
                    <PermissionBasedRoute
                      loggedInUser={loggedInUser}
                      requiredPermissions={[RolePermissions.UM_ViewUsersPage]}
                      element={<Users />}
                    />
                  }
                />
                <Route
                  path="/image-management/:tab?"
                  element={
                    <PermissionBasedRoute
                      loggedInUser={loggedInUser}
                      requiredPermissions={[RolePermissions.IM_ImageListTab_ViewImageslistTab]}
                      element={<ImageManagement />}
                    />
                  }
                />
                <Route
                  path="/reports"
                  element={
                    <PermissionBasedRoute
                      loggedInUser={loggedInUser}
                      requiredPermissions={[RolePermissions.R_ViewReportsTab]}
                      element={<Reports />}
                    />
                  }
                />
                <Route
                  path="/queries/:tab?"
                  element={
                    <PermissionBasedRoute
                      loggedInUser={loggedInUser}
                      requiredPermissions={[
                        RolePermissions.Q_ViewAllQueries,
                        RolePermissions.Q_ViewallQueriesunderAssignedStudies,
                        RolePermissions.Q_ViewQueriesAssignedtoUserRoleQueriesOnly,
                        RolePermissions.Q_ViewQueriesCases,
                        RolePermissions.Q_ViewQueriesImages
                      ]}
                      element={<Queries loggedInUser={loggedInUser} />}
                    />
                  }
                />
                <Route
                  path="/studies/:id/configure/:tab?"
                  element={
                    <PermissionBasedRoute
                      loggedInUser={loggedInUser}
                      requiredPermissions={[RolePermissions.S_Main_EditStudy]}
                      element={<StudyConfiguration />}
                    />
                  }
                />
                <Route
                  path="/studies/:id/:tab"
                  element={
                    <PermissionBasedRoute
                      loggedInUser={loggedInUser}
                      requiredPermissions={[RolePermissions.S_Main_ViewStudyPage]}
                      element={<Study />}
                    />
                  }
                />
                <Route
                  path="/studies/:id/"
                  element={
                    <PermissionBasedRoute
                      loggedInUser={loggedInUser}
                      requiredPermissions={[RolePermissions.S_Main_ViewStudyPage]}
                      element={<Study />}
                    />
                  }
                />
                <Route
                  path="/studies/:studyId/images/:id"
                  element={
                    <PermissionBasedRoute
                      loggedInUser={loggedInUser}
                      requiredPermissions={[RolePermissions.AP_ImageViewer_ViewimageViewer]}
                      element={<Image />}
                    />
                  }
                />
                <Route
                  path="/studies/:studyId/cases/:id"
                  element={
                    <PermissionBasedRoute
                      loggedInUser={loggedInUser}
                      requiredPermissions={[RolePermissions.AP_CaseViewer_Viewcaseview]}
                      element={<Case />}
                    />
                  }
                />
                <Route
                  path="/studies"
                  element={
                    <PermissionBasedRoute
                      loggedInUser={loggedInUser}
                      requiredPermissions={[RolePermissions.S_Main_ViewStudyPage]}
                      element={<Studies />}
                    />
                  }
                />
                <Route path="/" element={<Navigate replace to="/studies" />} />
              </Routes>
            </>
          )}
        </div>
      </Blaster>
    </BrowserRouter>
  );
};

export default App;
