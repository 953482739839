import { LatLngLiteral } from "leaflet";
import React from "react";
import { Circle } from "react-leaflet";

import { UUID } from "../models";
import AnnotationPopup from "./AnnotationPopup";
import { AppDispatch } from "../store";

interface Props {
  readonly date: string;
  readonly position: LatLngLiteral;
  readonly annotationId: UUID;
  readonly color: string | null;
  readonly canDelete: boolean;
  readonly weight: number;
  readonly scaleFactor: number;
  dispatch: AppDispatch;
}

export const defaultProps = {
  radius: 3,
  color: "black",
  fillColor: "#FFFF00",
  fillOpacity: 1,
  weight: 5,
  zIndex: 999
};

const PointAnnotation = ({
  position,
  date,
  annotationId,
  color,
  weight,
  canDelete,
  scaleFactor,
  dispatch
}: Props) => {
  const defaultRadius = 3;
  const props = {
    ...defaultProps,
    weight: 1,
    radius: defaultRadius * scaleFactor,
    ...(color ? { fillColor: color } : {})
  };
  const hprops = {
    ...defaultProps,
    weight: 1,
    radius: defaultRadius * scaleFactor * 7, // the multiplier for highlighting
    ...(color ? { fillColor: color } : {})
  };
  //console.log("point anno " + weight);
  return (
    <>
      {weight > 5 ? <Circle center={position} {...hprops}></Circle> : <></>}
      <Circle center={position} {...props}>
        <AnnotationPopup
          date={date}
          annotationId={annotationId}
          canDelete={canDelete}
          dispatch={dispatch}
        />
      </Circle>
    </>
  );
};

export default PointAnnotation;
